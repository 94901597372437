import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';

import BrandsLeft from '../../components/brandsLeft/brandsLeft';
import BrandsRight from '../../components/brandsRight/brandsRight';

import { resetTheStore } from '../../redux/actions/shared';
import { retrieveMonitoringProjects } from '../../redux/actions/monitoringProjects';

const useStyles = makeStyles((theme) => ({
    twoTables: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        height: '100%;'    
    },
    divider: {
        width: 14,
    },
  }));

const Brands = () => { 
    const classes = useStyles(); 
    const {monitoringProjects} = useSelector(state => state.monitoringProjects);
    const {unrelated, brands, selectedBrands} = useSelector(state => state.mprDetails);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetTheStore());
    }, []);

    useEffect(() => {
        dispatch(retrieveMonitoringProjects());
    }, [unrelated]);

    
    return (
            <div className={classes.twoTables}>
                <BrandsLeft />            
                <div className={classes.divider}></div>
                <BrandsRight /> 
            </div>
        )
}

export default Brands;
