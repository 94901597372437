import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
     //width: '25ch',
    },
  },
}));



export default function BasicTextFields(props) {
  const classes = useStyles();
  const {label, id, size, style, value, searchChange} = props;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField 
        id={id}
        label={label}
        type="search" 
        variant="outlined"
        color="secondary"
        size={size}
        style={style}
        // defaultValue={value}
        onChange={searchChange}
        />
    </form>
  );
}