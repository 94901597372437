import React from 'react';
// import PropTypes from 'prop-types';

// import {_baseApiUrl} from '../../redux/constants'; 

import './image.scss';

const Image = (props) => {
  const {path, height, width} = props;

  const handleClick = () => {
    console.log('Click');
  }
  
  return (
      <img
        src={path}
        alt={'Фото мониторинга'}
        height={height ? height : 'auto'}
        width={width ? width : 'auto'}
        onClick={() => handleClick()}
      />
  );
} 

export default Image;