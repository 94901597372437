import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { lightBlue } from '@material-ui/core/colors'; 
import Grid from '@material-ui/core/Grid';
import SingleSelectPlain from '../singleSelectPlain/singleSelectPlain';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import MultiSelectWithAllOption from '../multiSelectWithAllOption/multiSelectWithAllOption';

import { getFilteredMprDetails } from '../../redux/actions/mprDetails';
import { getFilteredMpReports } from '../../redux/actions/mpReports';
import { getJointCitiesFromMpDetailsByDate, getMpDetailsForMonitorings, getFilteredMpDetailsSimple, getFilteredClientsGoFromMpDetails } from '../../redux/actions/mpDetails';
import { setTheFilter, setTheMode, showAuto } from '../../redux/actions/shared';

import {_baseApiUrl} from '../../redux/constants';
import {getMonthsList} from '../../utils/shared';


const useStyles = makeStyles((theme) => ({  
    root: {
      display: 'flex',
      flexDirection: 'row', 
      fleWrap: 'nowrap',
    },      
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
        // marginBottom: '10px;',
    },
    export: {
      color: 'black',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginBottom: '20px;',
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    title: {
      padding: '10px;',
      paddingLeft: '24px;',
    },
    dialogcontent: {
      padding: '8px 24px 24px 24px',
    },
    dialogcontainer: {
      marginBottom: '10px',
    },

  }));

  function makeClientGoType(item){
    return {
      id: item.ctg_id,
      united: item.ctg_name,
    }
  }

const monthsList = getMonthsList(12);

export default function MonitoringsDialog2() {
  const classes = useStyles();

  const { filteredMpReports } = useSelector(state => state.mpReports);
  const { jointCities, filteredClientsGoFromMpDetails } = useSelector(state => state.mpDetails);
  
  
  const {user_role, user_permissions} = useSelector(state => state.users);

  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);

  
  const [month, setMonth] = useState('');
  const [mpd_city, setMpdCity] = useState([]);          // mp_details
  const [simple_mpr_id, setSimpleMprId] = useState([]); // mp_reports ОБЫЧНЫЙ
  const [mpr_id, setMprId] = useState([]);             // mp_reports МАССОВЫЙ
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilter = function(dtoObj, property, action){
    return function(newValue){
      dtoObj[property] = newValue;
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const loadData = () => {

    const filter = {
      mpr_planned_date: [].concat(month),
      mpd_city: mpd_city,
      // mpr_id: mpr_id,
      // simple_mpr_id: simple_mpr_id, 
    };

    console.log('filter: ', filter);

    dispatch(getFilteredMprDetails(Object.assign({}, filter, {mpr_id: mpr_id} )));
    //dispatch(getFilteredMpDetailsSimple(Object.assign({}, filter, {mpr_id: simple_mpr_id}, {simple_mpr: 'true'})));
    
    dispatch(getMpDetailsForMonitorings(simple_mpr_id));
    dispatch(setTheFilter(Object.assign({}, filter, {mpr_id: mpr_id}, {simple_mpr_id: simple_mpr_id})));
    dispatch(setTheMode('Monitorings2'));
    dispatch(showAuto('No'));

    handleClose();
  }

  console.log('jointCities: ', jointCities);
  console.log('month: ', month);
  console.log('mpd_city: ', mpd_city);
  console.log('simple_mpr_id: ', simple_mpr_id);
  console.log('mpr_id: ', mpr_id);
  console.log('filteredClientsGoFromMpDetails: ', filteredClientsGoFromMpDetails);
  


  return (
    <div>
      <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>

        <Grid item>
        <Tooltip title="Месяц -> Город -> Отчет обычного мониторинга -> Отчет масс мониторинга">
          <Button
              variant="contained"
              size="large"
              color="secondary" 
              className={classes.success}
              onClick={handleClickOpen}
          > 
              Фильтр 2
          </Button>
          </Tooltip>
        </Grid>


        <Grid item>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.title}>Фильтр</DialogTitle>
          
          <DialogContent className={classes.dialogcontent}>

              <form className={classes.container}>
                <Grid container spacing={1} display="flex" direction="column"  wrap="nowrap" alignItems="baseline">

                  <Grid item>
                    <SingleSelectPlain
                      label={'Месяц'}
                      style={{
                        width: 550,
                      }}
                      items={user_role === 'Admin' ? monthsList : monthsList.filter(item => user_permissions.months.includes(item))}
                      value={month}
                      setValue={setMonth}
                      fn={getFilter(
                        {
                          date: '',
                        },
                        'date',
                        getJointCitiesFromMpDetailsByDate
                      )}

                    />
                  </Grid>

                  {jointCities.length>0 && month &&
                  <Grid item>
                          <MultiSelectWithAllOptionPlain
                              style={{
                                width: 550,
                              }} 
                              label={'Город'}
                              items={jointCities}
                              value={mpd_city}
                              selected={mpd_city}
                              onChange={setMpdCity}
                              fn={getFilter(
                                {                        
                                  mpr_planned_date: [].concat(month),
                                  mpd_city: [],
                                  simple_mpr: 'true',                               
                                },
                                'mpd_city',
                                getFilteredMpReports
                              )}
                          />
                  </Grid>}

                  {filteredMpReports.length>0 && mpd_city.length>0 && month.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                        style={{
                          width: 550,
                        }}
                        label={'Отчеты обычного мониторинга'}
                        items={filteredMpReports}
                        value={simple_mpr_id}
                        selected={simple_mpr_id}
                        onChange={setSimpleMprId}
                        fn={getFilter(
                          {                               
                            mpr_planned_date: [].concat(month),
                            mpd_city: mpd_city,
                            simple_mpr: 'false', 
                          },
                          'simple_mpr_id',
                          getFilteredMpReports
                        )} 
                    />
                  </Grid>}

                  {simple_mpr_id.length>0 && mpd_city.length>0 && month.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                        style={{
                          width: 550,
                        }}
                        label={'Отчеты мониторинга'}
                        items={filteredMpReports}
                        value={mpr_id}
                        selected={mpr_id}
                        onChange={setMprId}
                        // fn={getFilter(
                        //   {                               
                        //     mpr_planned_date: [].concat(month),
                        //     mpd_city: mpd_city,
                        //     simple_mpr: 'false',
                        //     mpr_id: [], 
                        //   },
                        //   'mpr_id',
                        //   getFilteredClientsGoFromMpDetails
                        // )} 
                    />
                  </Grid>}

                  {/* {filteredClientsGoFromMpDetails.length>0 && mpr_id.length>0 && mpd_city.length>0 && month.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOption
                        style={{
                          width: 550,
                        }}
                        label={'Client_Go (из масс.мониторинга)'}
                        items={filteredClientsGoFromMpDetails}
                        value={clientGo}
                        selected={clientGo}
                        onChange={setClientGo}
                    />
                  </Grid>} */}
                
                </Grid>
              </form>
          </DialogContent>
          
          <DialogActions>
            <Button 
              onClick={handleClose} 
              color="primary"
            >
              Отмена
            </Button>

            {mpr_id.length>0 &&
            <Button 
              color="secondary"
              onClick={loadData}
            >
              Загрузить данные
            </Button>}
            
          </DialogActions>
        </Dialog>
      </Grid>

    </Grid>
      
    </div>
  );
}