import {
    RETRIEVE_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS,
    GET_ONE_MPR_DETAIL_BY_ID,
    // GET_MP_COMMUNICATION_FROM_MPR_DETAIL,
    UPDATE_ONE_MPR_DETAIL,
    UPDATE_MANY_FILTERED_MPR_DETAILS,
    SELECT_UNRELATED,
    GET_BRANDS_FROM_MPR_DETAILS,
    GET_BRANDS,
    GET_BRANDS_RELATED,
    SELECT_BRANDS,
    BIND_PHOTO_TO_THE_CLIENTS_GO,
    BIND_BRANDS_TO_THE_CLIENTS_GO,
    GET_MONTHS_RELATED_TO_THE_CLIENT,
    SELECT_MPR_DETAILS,
  } from "./types";
  
  import mprDetailsService from "../../services/mprDetailsService";
  import {isInitialState} from '../../utils/shared';

  const customTrim = (str) => {
    if(!str) { str = 'Бренд не проставлен'; } else { str = str.trim(); } 
    return str;
  };

  function qtyArr(arr){    
    let list2 = [...new Set(arr.map(obj => customTrim(obj.mprd_brand)))];
    // console.log('list2: ', list2);

    let newArr = [];
    for (let i = 0; i < list2.length; i++){
        let id = i;
        let name = list2[i];
        let occurancy = arr.filter(item => customTrim(item.mprd_brand) === name).length;
        // console.log('brand: ', name, ' occurancy: ', occurancy);
         
        newArr.push({
            id: id,
            united: name,
            qty: occurancy
        });
    }
    return newArr.sort();
  }
  
  export const selectUnrelated = (unrelated) => {
    return {
      type: SELECT_UNRELATED,
      payload: unrelated,
    }
  };

  export const retrieveMprDetails = (unrelated) => async (dispatch) => {
    try {
      const res = await mprDetailsService.getAll();
      const unrelatedMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;

      // const indexOfMaxValue = brandsTableData.reduce(function(previousValue, currentValue, index, array) {        
      //   return currentValue.qty > array[previousValue].qty ? index : previousValue;
      // }, 0);
      // console.log('indexOfMaxValue: ', indexOfMaxValue);
  
      dispatch({
        type: RETRIEVE_MPR_DETAILS,
        payload: unrelatedMprDetails,
      });
    } catch (err) {
      console.log(err); 
    }
  };

  export const getFilteredMprDetails = (filter, unrelated) => async (dispatch) => {

    if(isInitialState(filter)) {
      console.log('Фильтр не задан, запрос отменен')
      return;
    }
    
    const res = await mprDetailsService.filter(filter);
    const filteredMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;
  

    dispatch({
      type: GET_FILTERED_MPR_DETAILS,
      payload: filteredMprDetails,
    });
  };

  export const getBrands = (filter, unrelated) => async (dispatch) => {
    try {
      let brandsTableData;
      if( !filter || isInitialState(filter) ) {
        brandsTableData = [];
      }
      else {
      const res = await mprDetailsService.filter(filter);
      const filteredMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data; 
      brandsTableData =  qtyArr(filteredMprDetails);
    }

      dispatch({
        type: GET_BRANDS,
        payload: brandsTableData,
      });
    } 
    catch (err) {
      console.log(err);
    }
  };

  
  export const updateMprDetail = (id, data) => async (dispatch) => {
    try {
      console.log('MprDetail NEW DATA: ', data);
      const res = await mprDetailsService.update(id, data);

      dispatch({
        type: UPDATE_ONE_MPR_DETAIL,
        payload: res.data,
      });


    } catch (err) {
        console.log(err);
    }
  };

  // сюда прилетают уже обновленные на сервере данные и просто частично обновляют FilteredMprDetails в Store
  export const updateManyFilteredMprDetails = (mprDetailsArray) => {
    return {
      type: UPDATE_MANY_FILTERED_MPR_DETAILS,
      payload: mprDetailsArray,
    }
  };

  export const getBrandsFromMprDetails = () => async (dispatch) => {
    try {
      const res = await mprDetailsService.brands();

      dispatch({
        type: GET_BRANDS_FROM_MPR_DETAILS,
        payload: res.data.map(item => item.mprd_brand),
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const getBrandsRelatedToTheClientGo = (filter, cg_id) => async (dispatch) => {
    try {
      const res = await mprDetailsService.filter(filter);
      const mprDetailsRelatedToTheClientGo = res.data.filter(item => item.mprd_client_go === cg_id);
      const brandsTableData =  qtyArr(mprDetailsRelatedToTheClientGo);      
  
      dispatch({
        type: GET_BRANDS_RELATED,
        payload: brandsTableData,
      });
    } 
    catch (err) {
      console.log(err);
    }
  }; 

  export const selectBrands = (brandsArray) => { 
    return {
      type: SELECT_BRANDS,
      payload: brandsArray,
    }
  };

  export const bindBrandsToTheClientsGo = async (data, token) => {
    try {
      console.log('data before: ', data);
      const res = await mprDetailsService.updateMany(data, token);      
        return await Promise.resolve(res.data);
    } catch (err) {
        return await Promise.reject(err);
    }
  };
  
  export const getMonthsRelatedToTheClientGo = (cg_id) => async (dispatch) => {
    try {
      console.log('вызов mprDetailsService.getAll внутри getMonthsRelatedToTheClient');
      const res = await mprDetailsService.getAll();
      const mprDetailsRelatedToTheClientGo = res.data.filter(item => item.mprd_client_go === cg_id);
      const months =  [...new Set(mprDetailsRelatedToTheClientGo.map(item => item.mpr_planned_date.substring(0,7)))];      
  
      dispatch({
        type: GET_MONTHS_RELATED_TO_THE_CLIENT,
        // payload: mprDetailsRelatedToTheClientGo,
        payload: months,
      });
    } 
    catch (err) {
      console.log(err);
    }
  };

  export const bindPhotosToTheClientsGo = (id, data) => async (dispatch) => { 
    try {
      console.log(`id: ${id}, data before / Photos: `, data);
      const res = await mprDetailsService.update(id, data);
  
      dispatch({
        type: BIND_PHOTO_TO_THE_CLIENTS_GO,
        payload: res.data,
      });
  
    } catch (err) {
        console.log(err);
    }
  };

  export const selectMprDetails = (arr) => {
    return {
      type: SELECT_MPR_DETAILS,
      payload: arr,
    }
  }; 

    //   export const getMpCommunicationFromMprDetail = (id) => async (dispatch) => {
  //   try {

  //     const res = await mprDetailsService.get(id);
  //     // const res = await mpCommunicationsService.get(data.mprd_communications_id);

  //     dispatch({
  //       type: GET_MP_COMMUNICATION_FROM_MPR_DETAIL,
  //       payload: res.data.mpc_name,
  //     });


  //   } catch (err) {
  //       console.log(err);
  //   }
  // };

  
  // export const getOneMprDetailById = (id) => async (dispatch) => {
  //   try {

  //     const res = await mprDetailsService.get(id);

  //     dispatch({
  //       type: GET_ONE_MPR_DETAIL_BY_ID,
  //       payload: res.data,
  //     });


  //   } catch (err) {
  //       console.log(err);
  //   }
  // };