import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { lightBlue } from '@material-ui/core/colors'; 

import MultiSelectWithAllOption from '../../components/multiSelectWithAllOption/multiSelectWithAllOption';
import MultiSelectWithAllOptionPlain from '../../components/multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import usersService from '../../services/usersService';
import {getMonthsList} from '../../utils/shared';

const useStyles = makeStyles((theme) => ({  
    root: {
      display: 'flex',
      flexDirection: 'row', 
      fleWrap: 'nowrap',
    },      
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
        // marginBottom: '10px;',
    },
    export: {
      color: 'black',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginBottom: '20px;',
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    title: {
      padding: '10px;',
      paddingLeft: '24px;',
    },
    dialogcontent: {
      padding: '8px 24px 24px 24px',
    },
    griditem: {
        width: 300,
    }

  }));

export default function AdminEditUsersDialog(props) { 
    const classes = useStyles();

    const {user, setSelectionModel} = props;
    console.log('user: ', user);

    const [open, setOpen] = useState(false);
    
    const permissions = JSON.parse(user.user_permissions);
    console.log('permissions: ', permissions);

    const {monitoringProjects} = useSelector(state => state.monitoringProjects);
    const {filteredMpReports, monthsFromMpReports } = useSelector(state => state.mpReports);
    const {allCities} = useSelector(state => state.mpDetails);
    const {mtabs} = useSelector(state => state.mtabs);
    const {mactions} = useSelector(state => state.mactions);    

    const [userId, setUserId] = useState(user.user_id);    
    const [userFirstName, setUserFirstName] = useState(user.user_first_name);
    const [userFamilyName, setUserFamilyName] = useState(user.user_family_name);
    const [userEmail, setUserEmail] = useState(user.user_email);
    const [userPassword, setUserPassword] = useState(user.user_pass);
    const [userRole, setUserRole] = useState(user.user_role);

    const [tabs, setTabs] = useState(permissions.tabs ?? []);
    const [actions, setActions] = useState(permissions.actions ?? []);
    const [mpd_mp_id, setMpdMpId] = useState(permissions.projects ?? []);
    const [mpr_id, setMprId] = useState(permissions.reports ?? []);
    const [month, setMonth] = useState(permissions.months ?? []);
    const [cities, setCities] = useState(allCities.filter(item => permissions.cities.includes(item.id)) ?? []);

    // const [ctg_id, setClientsTypesGoId] = useState(); 
    // const [clientGo, setClientGo] = useState([]);
    // const [mpd_unit_type, setMpdUnitType] = useState([]);

    const handleUserFirstName = (event) => {
        setUserFirstName(event.target.value);
      };
    
    const handleUserFamilyName = (event) => {
        setUserFamilyName(event.target.value);
    };

    const handleUserEmail = (event) => {
        setUserEmail(event.target.value);
    };

    const handleUserPassword = (event) => {
        setUserPassword(event.target.value);
    };

    const handleUserRole = (event) => {
        setUserRole(event.target.value);
    };

    const updateUser = () => {

        const updatedUser = {
            user_first_name: userFirstName,
            user_family_name: userFamilyName,
            user_email: userEmail,
            user_pass: userPassword,
            user_role: userRole,
            user_permissions: JSON.stringify({
                tabs: tabs,
                actions: actions, 
                projects: mpd_mp_id,
                reports: mpr_id,
                months: month,                   
                cities: cities.map(item => item.id),
            })         
        };

        // console.log('updatedUser до: ', updatedUser);

        usersService.update(userId, updatedUser)
        .then((res) => console.log('updatedUser после: ', res.data))
        .then(() => handleClose());
    }
  
    const handleClickOpen = () => {
        setOpen(true);
      }

    const handleClose = () => {
       setOpen(false);
       setSelectionModel(null);
    };

    // console.log('allCities: ', allCities);

    return (
        <div>
            <Grid container spacing={1} alignItems={'center'} >        
                <Grid item>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        size="large"
                        onClick={handleClickOpen} 
                    >
                        Редактировать пользователя
                    </Button> 
                </Grid>
                
                <Grid item>
                    <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} display="flex" direction="column"  alignItems="baseline">
                                <Grid item className={classes.griditem}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="first_name"
                                        fullWidth
                                        label="Имя"
                                        value={userFirstName}
                                        onChange={handleUserFirstName}
                                    />
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="family_name"
                                        label="Фамилия"
                                        fullWidth
                                        value={userFamilyName}
                                        onChange={handleUserFamilyName}
                                    />
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="email"
                                        label="Эл.почта"
                                        type="email"
                                        fullWidth
                                        value={userEmail}
                                        onChange={handleUserEmail}
                                    />
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="password"
                                        label="Пароль"
                                        type="password"
                                        fullWidth
                                        value={userPassword}
                                        onChange={handleUserPassword}
                                    />
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <TextField
                                        id="standard-select"
                                        select
                                        fullWidth
                                        label="Роль"
                                        value={userRole}
                                        onChange={handleUserRole}
                                    >
                                        {['User', 'Admin'].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <MultiSelectWithAllOptionPlain 
                                        label={'Вкладки'} 
                                        items={mtabs}
                                        selected={tabs}
                                        value={tabs}
                                        onChange={setTabs}                                        
                                    />                                    
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <MultiSelectWithAllOptionPlain 
                                        label={'Действия'}
                                        items={mactions} 
                                        selected={actions}
                                        value={actions}
                                        onChange={setActions}                                        
                                    />                                    
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <MultiSelectWithAllOptionPlain 
                                        label={'Проекты'}
                                        items={monitoringProjects}
                                        selected={mpd_mp_id}
                                        value={mpd_mp_id}
                                        onChange={setMpdMpId}                                        
                                    />                                    
                                </Grid>

                                <Grid item className={classes.griditem}>
                                    <MultiSelectWithAllOptionPlain 
                                        label={'Отчеты'}
                                        items={filteredMpReports}
                                        selected={mpr_id}
                                        value={mpr_id}
                                        onChange={setMprId}                                        
                                    />                                    
                                </Grid>

                                <Grid item className={classes.griditem}> 
                                    <MultiSelectWithAllOptionPlain 
                                        label={'Месяцы'}
                                        items={getMonthsList(12)}
                                        selected={month}
                                        value={month}
                                        onChange={setMonth}                                        
                                    />                                    
                                </Grid>

                                <Grid item>
                                    <MultiSelectWithAllOption
                                        label={'Города'}
                                        items={allCities}
                                        limitTags={5}
                                        selected={cities}
                                        value={cities}
                                        onChange={setCities}                                          
                                    />
                                </Grid>

                            </Grid>
                        
                        </DialogContent>
                        
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Отмена
                            </Button>
                            <Button onClick={updateUser} color="secondary">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>            
          </Grid>
        </div>
    );
}        
