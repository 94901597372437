import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {setUser} from '../../redux/actions/users';

import {_baseApiUrl} from '../../redux/constants';

async function loginUser(credentials) {
  const url = `${_baseApiUrl}/auth/login`;
  console.log('url: ', url);

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
};

// async function getUser() {
//   const url = `${_baseApiUrl}/profile`;

//   return fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
//     },
//   })
//     .then(data => data.json());
// };

// function saveToken(userToken){
//   sessionStorage.setItem('token', JSON.stringify(userToken));
//   setToken(userToken.token);
// };
    

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
}));

export default function SignIn(props) {
  const {setToken} = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    })
    .then(result => {
        if(result.access_token) {
          console.log('result Token: ', result.access_token);
          console.log('result User: ', result.muser);
          setToken(result.access_token);
          sessionStorage.setItem('token', JSON.stringify(result.access_token));          
          sessionStorage.setItem('muser', JSON.stringify(result.muser));
          dispatch(setUser(result.muser));
        }
    });
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <legend>Авторизация</legend>
      <TextField
        label="Email"
        variant="outlined"
        color="secondary"
        type="email"
        required
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        label="Пароль"
        variant="outlined"
        color="secondary"
        type="password"
        required
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <div className={classes.buttons}>        
        <Button type="submit" variant="contained" color="secondary">
          Войти
        </Button>
        {/* <Button variant="contained" onClick={handleClose}>
          Отмена
        </Button> */}
      </div>
    </form>
  );
};
