import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import List from '../maketsList/maketsList';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '30px;',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '1rem;',
    color: 'black',
  },
}));

export default function ChipsArray() {
  const classes = useStyles();

  const {listFlag} = useSelector(state => state.shared);
  const {filteredMpDetails} = useSelector(state => state.mpDetails);
  console.log("filteredMpDetails / MaketsCommList: ", filteredMpDetails);

  const commArray = [...new Set(filteredMpDetails.filter(item => item.mprd_communication_id !== 0 ).map(item => item.mpc_name))].sort();
  console.log("commArray: ", commArray);

  return listFlag == 'No' 
    ? ( 
        <Box component="ul" className={classes.root}>
          {commArray.map((item, index) => {
            
            return (
              <li key={index}>
                <Chip
                //   icon={icon}
                    label={item}
                //   onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                    variant="outlined"
                    color="secondary"
                    className={classes.chip}
                />
              </li>
            );
          })}
        </Box>
      )
    : 
      (
        <Box className={classes.root}>
          <List list={commArray} />
        </Box>
      )
}