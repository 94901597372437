import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_MP_COMMUNICATIONS,
    CREATE_MP_COMMUNICATIONS,
  } from "../actions/types";
  
  const initialState = {
    mpCommunications: [],
  };
  
  function mpCommunicationsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }

      case RETRIEVE_MP_COMMUNICATIONS:
        if(!payload) return state; 
        return {
                ...state,
                mpCommunications: payload,
        }
        
      case CREATE_MP_COMMUNICATIONS:
        return {
          ...state,
          mpCommunications: state.mpCommunications.concat(payload),
      }

    //   case UPDATE_CLIENTS_GO:
    //     return clientsGo.map((clientsGo) => {
    //       if (clientsGo.id === payload.id) {
    //         return {
    //           ...clientsGo,
    //           ...payload,
    //         };
    //       } else {
    //         return clientsGo;
    //       }
    //     });
  
    //   case DELETE_CLIENTS_GO:
    //     return clientsGo.filter(({ id }) => id !== payload.id);
  
      default:
        return state;
    }
  };
  
  export default mpCommunicationsReducer;