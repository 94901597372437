import React, {Component} from 'react';
import { useSelector, useDispatch } from "react-redux";

import ImageGallery from 'react-image-gallery';

import './imageGallery.scss'; 

export default function MyGallery (props) {
    const {filteredMprDetails} = useSelector(state => state.mprDetails);

    const {selected} = props;

    console.log('Images filteredMprDetails: ', filteredMprDetails);
    console.log('selected: ', selected);

    const dispatch = useDispatch();

    const imagesForMprDetail = (selected, filteredMprDetails) => {

        const customTrim = (str) => {
          if(!str) {
            str = 'Бренд не проставлен';
          }
          else {
            str = str.trim();
          } 
          return str;
        };
    
        const to7 = (str) => {
          if (typeof str !== 'string') str = String(str);
          while(str.length<7) str = '0' + str; 
          return str
        };
        
        const toImages = (pathOriginal, pathThumbnail) => {
          return {
            original: pathOriginal,
            thumbnail: pathThumbnail
          }
        };

        let images0 = filteredMprDetails.filter(item => customTrim(item.mprd_brand).toLowerCase() === selected.toLowerCase());
            
        const imagesArr = images0
            ? images0
            : []; 
        console.log('imagesArr: ', imagesArr);
    
        let images1 = imagesArr.map(item => {
            let path = "https://monitoring.graceoutdoor.ru/" + to7(item.mprd_mpr_id) + "/" + to7(item.mprdp_photo_assigned_uf_id) + "_" + to7(item.uf_rand) + "." + item.uf_extension; 
            return toImages(path, null)
        });     
        console.log('images1: ', images1);
        
        if(images1.length>20) images1.length = 20;
    
        return images1;
    };

    const images = imagesForMprDetail(selected, filteredMprDetails);  
  
  return (
      <ImageGallery 
          items={images}
          // sizes={"100px;"}
          showThumbnails={false}
          // thumbnailPosition="left"
          // originalHeight='400px;'
          showIndex={true}
          showFullscreenButton={true}
      />
    )
};