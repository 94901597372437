import React from 'react';
import './spinner.css';

const Spinner = () => {
    return(
        <div className="tc">
            <h2>Идет загрузка ...</h2>
            <div className="loadingio-spinner-dual-ring-ejwvlkkwohj">
                <div className="ldio-6hskwgs33f">
                    <div></div>
                    <div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Spinner;
