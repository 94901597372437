import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';

import Photos from '../../pages/photos/photos';
import Brands from '../../pages/brands/brands';
import Makets from '../../pages/makets/makets';
import Monitorings from '../../pages/monitorings/monitorings';
import AdminPanel from '../../pages/admin/admin';


import {_baseApiUrl} from '../../redux/constants';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 14 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  }

}));

export default function NavTabs(props){

    const classes = useStyles();
    const dispatch = useDispatch();
    
    const {setToken} = props;

    const {user_role, user_permissions} = useSelector(state => state.users);

    const [value, setValue] = useState(0);
    const [error, setError] = useState(null);
 
    const handleChange = (event, value) => {
        setValue(value);
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        setToken('');
    };

    console.log('value: ', value);

    return (
      <div className={classes.root}>
        <AppBar position="static" color="transparent">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>

            <Tab 
              label="Фото"
              disabled={user_role === 'Admin' || user_permissions.tabs.includes('Photos') ? false : true}
            />
            
            <Tab 
              label="Бренды"
              disabled={user_role === 'Admin' || user_permissions.tabs.includes('Brands') ? false : true} 
            />

            <Tab 
              label="Макеты"
              disabled={user_role === 'Admin' || user_permissions.tabs.includes('Makets') ? false : true}   
            />

            <Tab 
              label="Мониторинги"
              disabled={user_role === 'Admin' || user_permissions.tabs.includes('Monitorings') ? false : true}   
            />

            {(user_role === 'Admin' || user_permissions.tabs.includes('AdminPanel') ) &&
            <Tab label="Администрирование" />}

            {/* <Tab label="Личный кабинет" href="https://app.weboutdoor.ru/" /> */}
          </Tabs>
          <Button
            variant="outlined"
            color="default"
            // className={classes.button}
            startIcon={<ExitToAppIcon />}
            onClick={logout}
          >
            выход
          </Button>
        </Toolbar>
        </AppBar> 
        {(user_role === 'Admin' || user_permissions.tabs.includes('Photos') ) && value === 0 && <TabContainer><Photos /></TabContainer>}
        {(user_role === 'Admin' || user_permissions.tabs.includes('Brands') ) && value === 1 && <TabContainer><Brands /></TabContainer>}
        {(user_role === 'Admin' || user_permissions.tabs.includes('Makets') ) && value === 2 && <TabContainer><Makets /></TabContainer>}
        {(user_role === 'Admin' || user_permissions.tabs.includes('Monitorings') ) && value === 3 && <TabContainer><Monitorings /></TabContainer>}
        {(user_role === 'Admin' || user_permissions.tabs.includes('AdminPanel') ) && value === 4 && <TabContainer><AdminPanel /></TabContainer>}
      </div>
    );
}