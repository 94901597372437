import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_CLIENTS_TYPES_GO,
  } from "../actions/types";
  
  const initialState = {    
    clientsTypesGo: [],
  };
  
  function clientsTypesGoReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_CLIENTS_TYPES_GO:
        if(!payload) return state; 
        return {
                ...state,
                clientsTypesGo: payload,
        }

      // case FILTER_CLIENTS_GO:
      //   if(!payload) {
      //     return {
      //       ...state, 
      //       filteredClientsGo: state.clientsGo
      //     }
      //   }
      //   else {
      //     return {
      //       ...state,
      //       filteredClientsGo: state.clientsGo.filter(item => item.united.toLowerCase().includes(payload.toLowerCase())),
      //     }
      //   }
        
      
      // case SELECT_CLIENTS_GO:
      //   if(payload.length === 0) {
      //     return {
      //       ...state,
      //       selectedClientsGo: [],
      //     }
      //   }
      //   else {
      //     return {
      //       ...state,
      //       selectedClientsGo: payload
      //     }
      //   }
        
      // case CREATE_CLIENTS_GO:
      //   return {
      //     ...state,
      //     clientsGo: state.clientsGo.concat(payload),
      // }      
      
  
    //   case UPDATE_CLIENTS_GO:
    //     return clientsGo.map((clientsGo) => {
    //       if (clientsGo.id === payload.id) {
    //         return {
    //           ...clientsGo,
    //           ...payload,
    //         };
    //       } else {
    //         return clientsGo;
    //       }
    //     });
  
    //   case DELETE_CLIENTS_GO:
    //     return clientsGo.filter(({ id }) => id !== payload.id);
  
      default:
        return state;
    }
  };
  
  export default clientsTypesGoReducer;