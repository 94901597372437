import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/musers`, { headers: authHeader() })
};

const create = (data) => {
    return axios.post(_baseApiUrl + `/musers`, data, { headers: authHeader() });
};

const update = (id, data) => {
    return axios.patch(_baseApiUrl + `/musers/${id}`, data, { headers: authHeader() });
};

export default {
    getAll,
    create,
    update,
};