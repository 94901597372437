import {
  RESET_THE_STORE_TO_INITIAL_STATE,
  RETRIEVE_MONITORING_PROJECTS,
  GET_FILTERED_MONITORING_PROJECTS,
  } from "../actions/types";
  
const initialState = {
    monitoringProjects: [],
    filteredMonitoringProjects: [],
};
  
function monitoringProjectsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_MONITORING_PROJECTS:
        if(!payload) return state;
        return {
                ...state,
                monitoringProjects: payload
        }

      case GET_FILTERED_MONITORING_PROJECTS:
        if(!payload) return state;
        return {
                ...state,
                filteredMonitoringProjects: payload,
        }
    
      default:
        return state;
  };
 }
  
export default monitoringProjectsReducer;