import {
    SAVE_LOG,
  } from "./types";
  
  import mlogsService from "../../services/mlogsService";
  import store from '../store';
  
  export const saveLog = (newMlog) => async (dispatch) => {
    try {
      const res = await mlogsService.create(newMlog);
  
      dispatch({
        type: SAVE_LOG,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  