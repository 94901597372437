import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
    const {label, id, options, width, value, setValue, fn} = props;       

  return (
    <Autocomplete
      multiple
      id={id}
      value={value}
      // defaultValue={options}
      onChange={(event, newValue) => {  
        setValue(newValue);
        if(fn) { fn(newValue) }
      }}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => String(option)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      style={{ width: width }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" color="secondary" label={label} />
      )}
      clearOnEscape
    />
  );
}