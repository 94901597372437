import {
  GET_MONTHS_FROM_MP_REPORTS,
  GET_FILTERED_MP_REPORTS,
  GET_FILTERED_MONITORING_PROJECTS,
  } from "./types";
  
import mpReportsService from "../../services/mpReports";


export const getFilteredMpReports = (data) => async (dispatch) => {
  try {
    const res = await mpReportsService.filter(data);
 
    dispatch({
      type: GET_FILTERED_MP_REPORTS,
      payload: [...new Set(res.data.map(item => item.mpr_id))].sort((a, b) => a > b ? -1 : 1),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMonthsFromMpReports = (data) => async (dispatch) => {
  try {
    const res = await mpReportsService.filter(data);

    dispatch({
      type: GET_MONTHS_FROM_MP_REPORTS,
      payload: [...new Set(res.data.map(item => item.mpr_planned_date.substring(0,7)))].sort((a, b) => a > b ? 1 : -1), // ? 
    });
  } catch (err) {
    console.log(err);
  }
};

