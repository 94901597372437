import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function SingleSelect(props) {
  const classes = useStyles();

  const {label, style, items, value, setValue, fn, array, id, name} = props;

  return (
    <div className={classes.root}>
      <Autocomplete
        id="tags-standard"
        style={style}
        options={items}
        value={value}
        onChange={(event, newValue) => {  
            setValue(newValue);
            console.log('new Value: ', newValue);
            if(fn) {
              if(array) {
                fn([].concat(newValue)) 
              }
              else {
                fn(newValue)
              }               
            }
        }}       
       
        getOptionSelected={(option, value) => option[id] === value[id] }
        getOptionLabel={(option) => option[name]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"            
            color="secondary"            
            label={label}
          />
        )}        
      />
     
    </div>
  ); 
}

