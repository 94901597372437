import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import usersService from '../../services/usersService';

// const useStyles = makeStyles((theme) => ({ 
//     griditem: {
//         width: 200,
//     }
// }));

export default function AdminPermissionsReport(props) { 
    // const classes = useStyles();

    const {cities} = props;

    const exportExcel = () => {

        usersService.getAll()
        .then((res) => {

            let reportData = res.data;

            let wb = new ExcelJS.Workbook();
            let workbookName = "WO_AccessReport.xlsx";
            let worksheetName = "Data";
            let ws = wb.addWorksheet(worksheetName);
        
            ws.columns = [
            {
                key: "user_id", 
                header: "ID", 
                width: 5 
            },
            {
                key: "user_name", 
                header: "ФИО", 
                width: 25 
            },
            {
                key: "user_role", 
                header: "Роль", 
                width: 10 
            },
            {
                key: "tabs", 
                header: "Вкладки", 
                width: 15
            },
            {
                key: "actions", 
                header: "Действия", 
                width: 50
            },
            {
                key: "projects", 
                header: "Проекты", 
                width: 15
            },
            {
                key: "reports", 
                header: "Отчеты", 
                width: 15
            },
            {
                key: "periods", 
                header: "Периоды", 
                width: 15
            },
            {
                key: "cities", 
                header: "Города", 
                width: 30
            },
            ];
        
            ws.getRow(1).alignment = { 
            vertical: 'top',
            wrapText: true
            };
        
            ws.getRow(1).font = {
            bold: true,
            color: { argb: "ffffff" },
            };
        
            ws.getRow(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00b050" }
            };
        
            for (let i = 0; i<reportData.length; i++){
        
            let permissions = JSON.parse(reportData[i].user_permissions);
            console.log('permissions ', i, permissions);
        
            ws.addRow({
                user_id: reportData[i].user_id,
                user_name: reportData[i].user_first_name + ' ' + reportData[i].user_family_name,
                user_role: reportData[i].user_role,
                tabs: permissions.tabs ? permissions.tabs.join() : '',
                actions: permissions.actions ? permissions.actions.join() : '',
                projects:permissions.projects ? permissions.projects.join() : '',
                reports: permissions.reports ? permissions.reports.join() : '',
                periods: permissions.periods ? permissions.periods.join() : '',
                cities: permissions.cities ? permissions.cities.map(item => cities.find(city => city.city_id === item).city_name_ru).join() : '',
            });
        
            ws.getRow(i+2).alignment = { 
                vertical: 'top',
                horizontal: 'left',
                wrapText: true
                };
            }
        
            wb.xlsx.writeBuffer()
            .then(function(buffer){
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),  
                    workbookName
                );
            });
        })
    }

    return (
        <div>
            <Grid container spacing={1} alignItems={'center'} > 
                <Grid item>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<GetAppIcon />}
                        onClick={exportExcel}
                    >
                        Отчет по правам
                    </Button>
                </Grid>            
            </Grid>
        </div>
    );
}        
