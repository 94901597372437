import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mpr_details_photos`, { headers: authHeader() }); 
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mpr_details_photos/${id}`, { headers: authHeader() })
}

const create = (data) => {
    return axios.post(_baseApiUrl + `/mpr_details_photos`, data, { headers: authHeader() });
}



export default {
    getAll,
    get,
    create,    
};