import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = async () => {
    return axios.get(_baseApiUrl + `/client_type_go`, { headers: authHeader() })
};
  
export default {
    getAll,
};