import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import { FormControlLabel, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { blue } from "@material-ui/core/colors";

import AdminCreateUsersDialog from '../adminUsersDialogs/adminCreateUsersDialog';
import AdminEditUsersDialog from '../adminUsersDialogs/adminEditUsersDialog';

import usersService from '../../services/usersService';
import {retrieveMtabs} from '../../redux/actions/mtabs';
import {retrieveMactions} from '../../redux/actions/mactions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    table: {
        width: '1000px',
    },
  }));

function makeUser(user){
  return {
    id: user.user_id,
    user_name: user.user_first_name + ' ' + user.user_family_name,
    user_email: user.user_email,
    user_role: user.user_role,
    user_permissions: user.user_permissions,
  }
}

export default function UsersTable(props) { 
    const classes = useStyles();
    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [selectionModel, setSelectionModel] = useState();
    
    useEffect(() => {
      dispatch(retrieveMtabs());
      dispatch(retrieveMactions());      
    }, []);

    useEffect(() => {
      usersService.getAll()
      .then((res) => {
        console.log('data: ', res.data);
        setUsers(res.data);
      });      
      
    }, [selectionModel]);

    const columns = [
        { field: 'id', headerName: 'id', width: 70, hide: true },
        { field: 'user_name', headerName: 'Пользователь', width: 250 },
        { field: 'user_email', headerName: 'Эл.почта', width: 250 },
        { field: 'user_role', headerName: 'Роль', width: 100 },
        { field: 'user_permissions', headerName: 'Разрешения', width: 250 },
      ];

    const rows = users.length>0
      ? users.map(item => makeUser(item))
      : [];    

  return (
    <div className={classes.root}>
        <Grid container spacing={2} direction="column" wrap="nowrap" alignItems="flex-start">

          <Grid item>
            <AdminCreateUsersDialog 
              setSelectionModel={setSelectionModel}
            />
          </Grid>

          {selectionModel &&
          <Grid item>
            <AdminEditUsersDialog 
              user={users.find(item => item.user_id === selectionModel )}
              setSelectionModel={setSelectionModel}
            />
          </Grid>}
          
          <Grid item>
            <DataGrid
                className={classes.table}
                autoHeight
                localeText={ruRU.props.MuiDataGrid.localeText} 
                rows={rows} 
                columns={columns} 
                pageSize={10}
                disableColumnMenu
                onSelectionModelChange={(newSelectionModel) => {
                  // console.log('newSelectionModel: ', newSelectionModel)
                  setSelectionModel(newSelectionModel.selectionModel[0]);
                }}
            />
          </Grid>
          
        </Grid>
    </div>
  );
}