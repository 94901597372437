import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Search from '../search/search';
import ImageGallery from '../imageGallery/imageGallery';
import DialogAddClientGo1 from '../brandsDialogToAddClientGo/brandsDialogToAddClientGo2';
import ClientsGoTableNew from '../brandsClientsGoTable/brandsClientsGoTable2';

import mprDetailsService from '../../services/mprDetailsService';

import { selectChosen, filterClientsGo, selectClientsGo, retrieveClientsGo } from '../../redux/actions/clientsGo';
import { selectUnrelated, selectBrands, getBrands, getFilteredMprDetails, updateManyFilteredMprDetails } from '../../redux/actions/mprDetails';
import { saveLog } from '../../redux/actions/mlogs';

import {_baseApiUrl} from '../../redux/constants';

const useStyles = makeStyles((theme) => ({
    brands: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        height: '100%;'    
    },
    half: {
      maxHeight: '900px;',
      overflow: 'hidden',
      width: '100%;',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minWidth: '400px;',
        width: '100%',
    },
    success: {
        flexBasis: '75%',
        padding: '14px;',
        // backgroundColor: theme.palette.primary.main, 
        color: '#fff',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        // backgroundColor: theme.palette.primary.main, 
        color: 'red',
        // backgroundColor: lightBlue[700]
    },
    photoContainer: {
        marginTop: '8px;',
    },
    typography: {
        padding: theme.spacing(2),
      },
    checkbox: {
        marginLeft: '14px;',
    }
  }));


const BrandsRight = () => { 
    const classes = useStyles();

    const {filter} = useSelector(state => state.shared);
    const {chosen, clientsGo, filteredClientsGo, selectedClientsGo } = useSelector(state => state.clientsGo);
    const {unrelated, filteredMprDetails, brands, selectedBrands} = useSelector(state => state.mprDetails);
    
    const {user_id, user_role, user_permissions} = useSelector(state => state.users)

    let i = null; // index of string to transfer to ADD NEW CLIENT_GO 
    if(brands.length>0 && selectedBrands.length === 1) {
        i = selectedBrands[0];
    }
    else if(brands.length>0 && selectedBrands.length>1) {
        i = selectedBrands.reduce(function(previousValue, currentValue, index){
            console.log('i: ', i);
            return +brands[currentValue].qty > +brands[previousValue].qty 
            ? currentValue
            : previousValue
          });
    };

    console.log('i: ', i);

    console.log('clientsGo: ', clientsGo);
    console.log('filteredClientsGo: ', filteredClientsGo);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveClientsGo());
    }, [chosen]);

    // const onChangeSearchClientsGo = (event) => {
    //     if(event.target.value) {
    //         dispatch(filterClientsGo(event.target.value));
    //     }
    // };

    const onChangeCheckBoxChosen = (event) => {
        if(unrelated) {
            dispatch(selectUnrelated(!unrelated))
        };

        if(!event.target.checked) {
            dispatch(selectUnrelated(true))
        };
        
        dispatch(selectChosen(event.target.checked));
    };
    
    const customTrim = (str) => {
        if(!str) { str = 'Бренд не проставлен'; } else { str = str.trim(); } 
        return str;
    };

    // работает для ПРИВЯЗКИ МАССИВА брендов
    const onBindBrandsToTheClientGo = () => { 

        if(user_role === 'User' && !user_permissions.actions.find(action => action === 'BIND_BRANDS_TO_THE_CLIENTS_GO')) {
            alert('В доступе отказано. Обратитесь к администратору');
            return;   
        }

        const token = JSON.parse(sessionStorage.getItem('token'));
        console.log('token: ', token);


        console.log('filteredMprDetails set_client RIGHT: ', filteredMprDetails);
        console.log('brands: ', brands);
        console.log('selectedBrands: ', selectedBrands);
        console.log('toClientGo: ', selectedClientsGo);

        const brandsList = selectedBrands.map(item => brands[item].united);
        console.log('brandsList: ', brandsList);
        

        const mprDetail = filteredMprDetails.filter(item => brandsList.includes(customTrim(item.mprd_brand)));
        console.log('mprDetail через brandList: ', mprDetail);

        const newMprDetails = mprDetail.map(item => ({
            mprd_id: item.mprd_id,
            mprd_client_go: selectedClientsGo[0]
        }));

        console.log('newMprDetails: ', newMprDetails);

        const mLog = {
            ml_mu_id: user_id,
            ml_mt_id: 2,
            ml_ma_id: 3, 
            ml_qty: newMprDetails.length,
            ml_comments: JSON.stringify(newMprDetails),
          }

        const url = `${_baseApiUrl}/mpr_details/set_client`;
        console.log('Url: ', url);
    
        const requestOptions = {
          method: 'PUT',
          //headers: {'Content-Type': 'application/json; charset=utf-8'},
          headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization' : `Bearer ${token}` },
          body: JSON.stringify(newMprDetails)
        };
    
        fetch(url, requestOptions)        
        .then(res => res.json())
        .then(
          (data) => {
            console.log('запрос ПРИВЯЗАТЬ отработал');
            console.log('data: ', data);
          })
        .then(() => console.log('запрос ПРИВЯЗАТЬ отработал'))
        .then(() => {
            dispatch(getBrands(filter, unrelated));
            dispatch(getFilteredMprDetails(filter, unrelated));
            dispatch(selectBrands([]));
        })
        .then(() => {
            dispatch(saveLog(mLog))
              .then(data => console.log('ПРИВЯЗАТЬ - ЛОГ СОХРАНЕН:', data))
              .catch(e => {
                console.log(e);
              });
          })    
    }

    const onUnbindBrandsFromTheClientGo = () => {

        if(user_role === 'User' && !user_permissions.actions.find(action => action === 'UNBIND_BRANDS_FROM_THE_CLIENTS_GO')) {
            alert('В доступе отказано. Обратитесь к администратору');
            return;   
        }
        
        const brandsArray = brands.filter(item => selectedBrands.includes(item.id)).map(item => item.united.toLowerCase());
        console.log('brandsArray: ', brandsArray);

        const mprDetailsToUnbind = filteredMprDetails.filter(item => item.mprd_client_go === +selectedClientsGo[0] && brandsArray.includes(customTrim(item.mprd_brand).toLowerCase()));
        console.log('mprDetails to unbind: ', mprDetailsToUnbind);

        const newMprDetails1 = mprDetailsToUnbind.map(item => ({
          ...item,
          mprd_client_go: 0
        }));
        console.log('newMprDetails1 RIGHT: ', newMprDetails1);
    
        // const url = `${_baseApiUrl}/mpr_details/set_client`;

        const mLog1 = {
            ml_mu_id: user_id,
            ml_mt_id: 2,
            ml_ma_id: 4, 
            ml_qty: newMprDetails1.length,
            ml_comments: JSON.stringify(newMprDetails1),
          }
    
        // const requestOptions = {
        //   method: 'PUT',
        //   headers: {'Content-Type': 'application/json; charset=utf-8'},
        //   body: JSON.stringify(newMprDetails1)
        // };

        mprDetailsService.updateMany(newMprDetails1)
        //.then(res => res.json())
        // .then(
        //   (data) => {
        //     console.log('data: ', data);
        //   })
        .then((data) => {
                console.log('data: ', data);
        })
        .then((data) => console.log('ЗАПРОС ОТВЯЗАТЬ ОТРАБОТАЛ'))
        .then((data) => {
            //dispatch(getBrands(filter, unrelated));
            dispatch(updateManyFilteredMprDetails(data));
            dispatch(selectBrands([]));
        })
        .then(() => {
            dispatch(saveLog(mLog1))
              .then(data => console.log('ОТВЯЗАТЬ - ЛОГ СОХРАНЕН:', data)) 
              .catch(e => {
                console.log(e);
              });
        });          
    }

    // export const createClientGo = (newClientGo) => async (dispatch) => {
    //     try {
    //       const res = await clientsGoService.create(newClientGo);
      
    //       dispatch({
    //         type: CREATE_CLIENTS_GO,
    //         payload: _transformClientGo(res.data),
    //       });
      
    //       return Promise.resolve(res.data);
    //     } catch (err) {
    //       return Promise.reject(err);
    //     }
    //   };

    const onDeleteClientGo = () => {

        if(user_role === 'User' && !user_permissions.actions.find(action => action === 'DELETE_THE_CLIENT_GO')) {
            alert('В доступе отказано. Обратитесь к администратору');
            return;   
        }
        console.log('удаляем: ', selectedClientsGo[0]);

        if(brands.length>0) {
            alert('Перед удалением отвяжите все Бренды');
            return;
        }
        else {
            async function removeItem(id) {
                try {
                    let response = await fetch(`${_baseApiUrl}/client_go/${id}`, {
                        method: "DELETE",
                    });
                } catch (err) {
                    console.log('Ошибка сервера: ', err)
                } 
            }

            const mLog2 = {
                ml_mu_id: user_id,
                ml_mt_id: 2,
                ml_ma_id: 5, 
                ml_qty: 1,
                ml_comments: JSON.stringify(clientsGo.find(item => item.cg_id === selectedClientsGo[0])),
              }
                   
            removeItem(selectedClientsGo[0])
                .then(() => console.log('ЗАПРОС УДАЛИТЬ ОТРАБОТАЛ'))
                .then(() => {
                    dispatch(saveLog(mLog2))
                      .then(data => console.log('УДАЛИТЬ КЛИЕНТА - ЛОГ СОХРАНЕН:', data))
                      .catch(e => {
                        console.log(e);
                      });
                });
            
            dispatch(retrieveClientsGo());
            dispatch(selectClientsGo([]));
            dispatch(getFilteredMprDetails(filter, unrelated));
        }
    }

    return (
        <div className={classes.half}>
            <Grid container spacing={2} display="flex" wrap="nowrap" alignItems="center">
                
                {((filteredMprDetails.length>0 && brands.length>0) || (filteredMprDetails.length>0 && chosen)) && 
                <Grid item>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="end"
                        control={<Checkbox checked={chosen} onChange={onChangeCheckBoxChosen} color="secondary" />}
                        label="Выбранные"
                        labelPlacement="end"
                    />
                </Grid>}

                {((filteredMprDetails.length>0 && brands.length>0) || (filteredMprDetails.length>0 && chosen)) && 
                <Grid item>
                    <Search
                        label={'Client_GO'}
                        id={'clientGO'}
                        // value={clientsGoSearchField}
                        searchChange={(event) => dispatch(filterClientsGo(event.target.value))} 
                    />
                </Grid>}

                {unrelated && selectedBrands.length>0 && selectedClientsGo.length>0 &&    
                <Grid item>                    
                    <Button 
                        variant="contained"
                        color="secondary"
                        size="large"
                        className={classes.success}
                        onClick={onBindBrandsToTheClientGo}
                    >
                        Привязать
                    </Button>
                </Grid>}

                {chosen && selectedClientsGo.length>0 &&    
                <Grid item>                    
                    <Button 
                        variant="outlined"
                        color="primary"
                        size="large"
                        className={classes.additional}
                        onClick={onUnbindBrandsFromTheClientGo}
                    >
                        Отвязать
                    </Button>
                </Grid>}

                {unrelated && brands.length>0 && selectedBrands.length>0 && 
                <Grid item>
                    <DialogAddClientGo1 
                        selected={brands[i].united}
                        />
                </Grid>}

                {chosen && selectedClientsGo.length>0 &&   
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.cancel}
                        size="large"
                        onClick={onDeleteClientGo}
                    >
                        Удалить
                    </Button>
                </Grid>}
            </Grid>

            {(brands.length>0 || chosen) &&
            <ClientsGoTableNew 
                rows={filteredClientsGo}
            />}
                        
            {brands.length>0 && selectedBrands.length>0 && !chosen &&
            <Grid item>
                <ImageGallery selected={brands[i].united} /> 
            </Grid>}

    </div> 
)}

export default BrandsRight;
