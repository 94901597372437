import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mp_reports`, { headers: authHeader() }); 
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mp_reports/${id}`, { headers: authHeader() })
}

const filter = (filter) => {
    return axios.post(_baseApiUrl + `/mp_reports/filter`, filter, { headers: authHeader() });
};

export default {
    getAll,
    get,
    filter,
};