import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
        return axios.get(_baseApiUrl + `/client_go`, { headers: authHeader() });
}

const get = (id) => {
        return axios.get(_baseApiUrl + `/client_go/${id}`, { headers: authHeader() });
}
    
const getByType = (ctg_id) => {
        return axios.get(_baseApiUrl + `/client_go/get_by_type/${ctg_id}`, { headers: authHeader() });
}

const filter = (filter) => {
        return axios.post(_baseApiUrl + `/client_go/filter`, filter, { headers: authHeader() });
}

const create = (data) => {
        return axios.post(_baseApiUrl + `/client_go`, data, { headers: authHeader() });
}

export default {
    getAll,
    get,
    getByType,
    filter,
    create,
};