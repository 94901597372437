import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

import { selectBrands } from '../../redux/actions/mprDetails';
import { filterClientsGo } from '../../redux/actions/clientsGo';

const columns = [
  { field: 'id', headerName: 'id', width: 70, hide: true },
  { field: 'united', headerName: 'Название бренда', flex: 1 },
  { field: 'qty', headerName: 'Кол-во сторон', type: 'number', flex: 0.3 }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    table: {
        flexGrow: 1,
    },
  }));

export default function BrandsTableNew(props) {
    const classes = useStyles();

    const {rows} = props;

    const {brands, selectedBrands} = useSelector(state => state.mprDetails);
    console.log('selectedBrands: ', selectedBrands);

    const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <DataGrid
        className={classes.table}
        autoHeight
        localeText={ruRU.props.MuiDataGrid.localeText} 
        rows={rows} 
        columns={columns}
        sortModel={[
          {
            field: 'qty',
            sort: 'desc',
          },
        ]}
        pageSize={12} 
        checkboxSelection 
        disableColumnMenu
        disableColumnSelector

        onSelectionModelChange={(newSelection) => {
            dispatch(selectBrands(newSelection.selectionModel));

            newSelection.selectionModel.length>0
              ? dispatch(filterClientsGo(brands[newSelection.selectionModel[0]].united))
              : dispatch(filterClientsGo(''));
        }}
        selectionModel={selectedBrands} 
        id={"data-grid"}
        />
    </div>
  );
}