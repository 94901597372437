import {
  SET_USER,
  } from "../actions/types";

  const initialState = {
    user_id: null,
    user_email: '',
    user_role: '',
    user_permissions: {
      tabs: [],
      actions: [],
      projects: [],
      reports: [],
      months: [],
      cities: [],
      types: [],
    }    
  };
  
  // const initialState = {
  //   userId: null,
  //   username: '',
  //   role: '',
  //   permissions: {
  //     tabs: [],
  //     actions: [],
  //     projects: [],
  //     reports: [],
  //     months: [],
  //     cities: [],
  //     types: [],
  //   }    
  // };
  
  function usersReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case SET_USER:
        return {
          ...state,
          user_id: payload.user_id,
          user_email: payload.user_email,
          user_role: payload.user_role,
          user_permissions: JSON.parse(payload.user_permissions),
        }
  
      default:
        return state;
    }
  };
  
  export default usersReducer;