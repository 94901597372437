import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mp_details`, { headers: authHeader() });
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mp_details/${id}`, { headers: authHeader() })
}

const filter = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/filter`, filter, { headers: authHeader() });
};

const filterSimple = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/filter_simple`, filter, { headers: authHeader() });
}; 

const allCities = () => {
    return axios.get(_baseApiUrl + `/mp_details/get_cities_from_mp_details`, { headers: authHeader() });
};

const cities = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/get_cities_from_mp_details`, filter, { headers: authHeader() });
};

const jointCities = (month) => {
    return axios.post(_baseApiUrl + `/mp_details/get_joint_cities_from_mp_details_by_date`, month, { headers: authHeader() });
};

const unitTypes = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/get_unit_types_from_mp_details`, filter, { headers: authHeader() });
};

const clientTypesGo = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/get_client_types_go_from_mp_details`, filter, { headers: authHeader() });
};

const clientsGo = (filter) => {
    return axios.post(_baseApiUrl + `/mp_details/get_clients_go_from_mp_details`, filter, { headers: authHeader() });
};

const geoAuto = (reports) => {
    return axios.post(_baseApiUrl + `/mp_details/geo_auto`, reports, { headers: authHeader() }); 
};

const getMpDetailsForMonitorings = (id) => {  // mpr_id
    return axios.get(_baseApiUrl + `/mp_details/mp_details_for_monitorings/${id}`, { headers: authHeader() });
};


export default {
    getAll,
    get,
    filter,
    filterSimple,
    allCities,
    cities,
    jointCities,
    clientTypesGo,
    clientsGo,
    unitTypes,
    geoAuto,
    getMpDetailsForMonitorings,
};