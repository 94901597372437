import React, { Component } from 'react';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import Grid from '@material-ui/core/Grid';


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null
    }
    this.fileHandler = this.fileHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.openNewPage = this.openNewPage.bind(this);
    this.fileInput = React.createRef();
  }

  renderFile = (fileObj) => {
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          this.setState({
            dataLoaded: true,
            cols: resp.cols,
            rows: resp.rows
          });
        }
      }); 
  }

  fileHandler = (event) => {    
    if(event.target.files.length){
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      
      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false
        });
        this.renderFile(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid: true,
          uploadedFileName: ""
        })
      }
    }               
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  }

  openNewPage = (chosenItem) => {
    const url = chosenItem === "github" ? "https://github.com/ashishd751/react-excel-renderer" : "https://medium.com/@ashishd751/render-and-display-excel-sheets-on-webpage-using-react-js-af785a5db6a7";
    window.open(url, '_blank');
  }

  render() {

    console.log('rows: ', this.state.rows);
    console.log('cols: ', this.state.cols);

    return (
      <div>
  
      <Grid container spacing={2} direction="column" wrap="nowrap" alignItems="flex-start">
        
          <Grid item>
            <form>
              <div>
                  {/* <button onClick={this.openFileBrowser.bind(this)}><i className="cui-file"></i> Browse&hellip;</button> */}

                  <input 
                    type="file" 
                    onChange={this.fileHandler.bind(this)} 
                    ref={this.fileInput} 
                    onClick={(event)=> { event.target.value = null }} 
                    style={{"padding":"10px"}} 
                  />                                

                  <input 
                    type="text" 
                    value={this.state.uploadedFileName} 
                    readOnly 
                    invalid={this.state.isFormInvalid} 
                  />                                              

       
              </div>  
            </form>


          </Grid>

        {this.state.dataLoaded && 
          <Grid item>            
              <OutTable 
                data={this.state.rows} 
                columns={this.state.cols} 
              />            
          </Grid>}  
        </Grid>
      </div>
    );
  }
}

export default App;