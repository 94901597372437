import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

import MultiSelectWithAllOptionPlain from '../../components/multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import MonitoringsPhotos from '../monitoringsPhotos/monitoringsPhotos'; 

import { selectClientsGo } from '../../redux/actions/clientsGo';

import { RenderCellExpand } from '../../utils/renderCellExpand';

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true }, 
  { field: 'maket', headerName: 'Макет', width: 100 },
  { field: 'addressSimple', headerName: 'Адрес обычного мониторинга', renderCell: RenderCellExpand, flex: 1 },
  { field: 'sideSimple', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'typeSimple', headerName: 'Тип констр.', sortable: false, width: 120 },
];

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth:650,
      minHeight: 500, 
      flexGrow: 1,
    },
    half: {
      maxHeight: '900px;',
    //   overflow: 'hidden',
      maxWidth: '50vw',
      display: 'flex',
      flexDirection: 'column',
    },
  }));

export default function MonitoringsLeftTable(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const {rows, selectedIndex} = props;
    const {mode} = useSelector(state => state.shared);
    const {filteredMpDetailsSimple} = useSelector(state => state.mpDetails);
    
    const filteredMakets =  [...new Set(filteredMpDetailsSimple.map(item => item.maket))].sort();
    
    const [makets, setMakets] = useState([]);
    const [viewedAddressSimple, setViewedAddressSimple] = useState();

    let rows1;
    switch (selectedIndex) {
      case 2:   // binded
        rows1 = rows.filter(item => item.photos && item.mprdp_deleted === 0);
        break;
      case 1:   // unbinded
        rows1 = rows.filter(item => !item.photos || item.mprdp_deleted === 1);
        break;
      default:  // all
        rows1 = rows; 
        break;
    }

    const rowsFinalSimple = makets.length>0
      ? rows1.filter(item => makets.includes(item.maket))
      : rows1; 

    const handleRowSelection = (e) => {  
       setViewedAddressSimple(filteredMpDetailsSimple.find(jtem => jtem.id === +e.selectionModel));
       dispatch(selectClientsGo([].concat(filteredMpDetailsSimple.find(jtem => jtem.id === +e.selectionModel))))
    }

    console.log('filteredMakets / LEFT TABLE: ', filteredMakets);
    console.log('viewedAddressSimple / LEFT TABLE: ', viewedAddressSimple);
    console.log('filteredMpDetailsSimple / LEFT TABLE: ', filteredMpDetailsSimple);
    console.log('selectedIndex: / LEFT TABLE', selectedIndex);   

  return (
    <div className={classes.half}>
      <Grid container spacing={1} direction="column" alignItems="flex-start">

        
        <Grid container spacing={1} direction="row" alignItems="flex-start"> 
        
            <Grid item>    
                <MultiSelectWithAllOptionPlain
                    style={{
                      width: 300,
                      marginLeft: 10,
                    }}
                    size={'small'}
                    limitTags={2}
                    label={'Макет'}
                    items={filteredMakets}
                    value={makets}
                    selected={makets}
                    onChange={setMakets}
                />
            </Grid>        
            

            {/* {autoFlag === 'No' &&
            <Grid item>
              <Search
                  label={'Адрес'}
                  size={'small'}
                  style={{
                    width: 200,
                    marginLeft: 10,
                  }}
                  id={'clientGO'}
                  value={search}
                  searchChange={onChangeSearch} 
              />
            </Grid>
            } */}

        </Grid>
        

          <Grid item>
            <DataGrid
              className={classes.table}
              autoHeight
              localeText={ruRU.props.MuiDataGrid.localeText} 
              rows={rowsFinalSimple} 
              columns={columns}
              sortModel={[
                {
                  field: 'addressSimple',
                  sort: 'asc',
                },
              ]}
              pageSize={7} 
              //checkboxSelection 
              disableColumnMenu
              disableColumnSelector
              //disableSelectionOnClick
              onSelectionModelChange = {handleRowSelection}
              id={"data-grid-left"}
            />
          </Grid>

        {viewedAddressSimple && rowsFinalSimple.length>0 && 
          <Grid item>
            <MonitoringsPhotos
                item={viewedAddressSimple} 
            />
          </Grid>}
        </Grid>
    </div>

  );
}