import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'; 

import MaketsDialog1 from '../../components/maketsDialogs/maketsDialog1';
import MaketsDialog2 from '../../components/maketsDialogs/maketsDialog2';
import MaketsDialog3 from '../../components/maketsDialogs/maketsDialog3';

import MaketsActions from '../../components/maketsActions/maketsActions';
import MaketsPhotos from '../../components/maketsPhotos/maketsPhotos';

import { resetTheStore } from '../../redux/actions/shared'; 
import {retrieveClientsTypesGo} from '../../redux/actions/clientsTypesGo';
import {retrieveMpCommunications} from '../../redux/actions/mpCommunications';

import {_baseApiUrl} from '../../redux/constants';


const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
      },
  }));

const Makets = () => { 
    const classes = useStyles();

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(resetTheStore());  
    }, []);
    
    useEffect(() => {        
        dispatch(retrieveClientsTypesGo());
        dispatch(retrieveMpCommunications());
    }, []);   

    
    return (
            <div>

                <Grid container flexDirection={'row'}>
                    <MaketsDialog1 />
                    <MaketsDialog2 />
                    <MaketsDialog3 />
                    <MaketsActions />
                </Grid>

                <Grid container spacing={2} >
                    <MaketsPhotos />
                </Grid>
            </div>
    )
}

export default Makets; 