import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/monitoring_projects`, { headers: authHeader() });
};

const get = async (id) => {
    return axios.get(_baseApiUrl + `/monitoring_projects/${id}`, { headers: authHeader() })
}

const filter = async (data) => {
    return axios.post(_baseApiUrl + `/mp_reports/filter`, data, { headers: authHeader() });  // this endpoint is common for both: mp_reports and monitoring_projects 
};  

export default {
    getAll,
    get,
    filter,
};