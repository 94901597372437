import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './redux/store';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';
import { ruRU } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#EEA17E',
      main: '#DC5B21',
      dark: '#8F340B',
      contrastText: '#000',
    },
    secondary: {
      light: '#9ED5BA',
      main: '#70AB8F',
      dark: '#5F8070',
      contrastText: '#000',
    },
    error: {
      light: indigo[300],
      main: indigo[500],
      dark: indigo[700],
      contrastText: '#000',
    },
  },
  // props: {
  //   MuiGridListTile: {

  //   }
  // },
  // overrides: {
  //   // Style sheet name ⚛️
  //   MuiButton: {
  //     // Name of the rule
  //     text: {
  //       // Some CSS
  //       color: 'white',
  //     },
  //   },
  // },
}, ruRU);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
