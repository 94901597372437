import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import MonitoringsPhotos from '../monitoringsPhotos/monitoringsPhotos';
import MonitoringsImage from '../monitoringsImage/monitoringsImage';

import {selectMonitoringsAutoTableAddresses} from '../../redux/actions/mpDetails';

import { RenderCellExpand } from '../../utils/renderCellExpand';
import { to7digitals } from '../../utils/shared';

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true },
  { field: 'maket', headerName: 'Макет', width: 100,  flex: 0.2 },
  { field: 'addressSimple', headerName: 'Адрес обычного мониторинга', width: 350, sortable: false, renderCell: RenderCellExpand},
  { field: 'sideSimple', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'typeSimple', headerName: 'Тип констр.', sortable: false, width: 120 },
  { field: 'mid', headerName: 'id масс мониторинга', width: 70, hide: true },
  { field: 'address', headerName: 'Адрес масс. мониторинга', width: 350, sortable: false, renderCell: RenderCellExpand },
  { field: 'side', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'type', headerName: 'Тип констр.', sortable: false, width: 120},
  { field: 'clientGo', headerName: 'Client_Go', width: 100, flex: 0.2}
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    table: {
        flexGrow: 1,
    },
  }));

export default function MonitoringsAutoTable(props) {
    const classes = useStyles();

    const {filteredMpDetailsSimple} = useSelector(state => state.mpDetails);
    const {filteredMprDetails} = useSelector(state => state.mprDetails);

    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [viewedAddress, setViewedAddress] = useState();
    const [viewedAddressSimple, setViewedAddressSimple] = useState();

    const {rows, selectedIndex} = props;

    let rowsFinalSimple;
    switch (selectedIndex) {
      case 2:  // binded
        rowsFinalSimple = rows.filter(item => item.photos && item.mprdp_deleted === 0);
        break;
      case 1:  // unbinded
        rowsFinalSimple = rows.filter(item => !item.photos || item.mprdp_deleted === 1);
        break;
      default: // all                
        rowsFinalSimple = rows; 
        break;
    }
    
    const dispatch = useDispatch();

    const mpDetail = filteredMprDetails.find(item => item.mprd_id === viewedAddress);
    const item = mpDetail 
      ? {photo: "https://monitoring.graceoutdoor.ru/" + to7digitals(mpDetail.mprd_mpr_id) + "/" + to7digitals(mpDetail.mprdp_photo_assigned_uf_id) + "_" + to7digitals(mpDetail.uf_rand) + "_900." + mpDetail.uf_extension, passport: mpDetail.mpd_photo_url} 
      : {};

    console.log('selectedAddresses: ', selectedAddresses);
    console.log('viewedAddress: ', viewedAddress);
    console.log('viewedAddressSimple: ', viewedAddressSimple);
    console.log('mpDetail: ', mpDetail);
    console.log('item: ', item);
    

  return rows.length>0 
    ? (
    <div className={classes.root}>
      <DataGrid
        className={classes.table}
        autoHeight
        localeText={ruRU.props.MuiDataGrid.localeText} 
        rows={rowsFinalSimple} 
        columns={columns}
        sortModel={[
          {
            field: 'addressSimple',
            sort: 'asc',
          },
        ]}
        pageSize={7} 
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        onRowClick={(e) => {
          setViewedAddress(e.row.mid);
          setViewedAddressSimple(e.row.id)
        }}

        onSelectionModelChange={(newSelectionModel) => {
          setSelectedAddresses(rows.filter(item => newSelectionModel.includes(item.id)));
          dispatch(selectMonitoringsAutoTableAddresses(rows.filter(item => newSelectionModel.includes(item.id))));
        }}
        selectionModel={selectedAddresses.map(item => item.id)} 
        />

        <Grid container spacing={2} style={{maxHeight: 250}}>

          <Grid item>
              {viewedAddressSimple &&
                <MonitoringsPhotos
                    item={filteredMpDetailsSimple.find(item => item.id === viewedAddressSimple)} 
              />}
          </Grid> 

          <Grid item> 
            {viewedAddress && item && 
            <MonitoringsImage
              item={item}
              height={250}
              width={333} 
            />}
          </Grid>        
       
        </Grid>
    </div>
  )

  : (
    <div>Loading ...</div>
  )
}