import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Search from '../search/search';

import BrandsDialog from '../brandsDialog/brandsDialog';
import BrandsTableNew from '../brandsTable/brandsTable2';
import BrandsReport from '../bransdsReport/brandsReport';

import { SELECT_UNRELATED, FILTER_BRANDS } from '../../redux/actions/types';

import {selectUnrelated, retrieveMprDetails, getFilteredMprDetails, getBrands, filterBrands, selectBrands} from '../../redux/actions/mprDetails';
 

const useStyles = makeStyles((theme) => ({
    brands: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        height: '100%;'    
    },
    half: {
      maxHeight: '900px;',
    //   overflow: 'hidden',
      width: '100%;',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minWidth: '400px;',
        width: '100%',
    },
    success: {
        flexBasis: '75%',
        marginRight: '14px;',
        padding: '14px;',
        // backgroundColor: theme.palette.primary.main, 
        color: '#fff',
        overflow: 'visible',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    photoContainer: {
        marginTop: '8px;',
    },
    typography: {
        padding: theme.spacing(2),
      },
    checkbox: {
        marginLeft: '14px;',
    },
    search: {
        margin: '0',
    }
  }));

const BrandsLeft = () => { 
    const classes = useStyles(); 

    const {filter} = useSelector(state => state.shared);
    const {monitoringProjects} = useSelector(state => state.monitoringProjects);

    const {unrelated, mprDetails, filteredMprDetails, brands, filteredBrands} = useSelector(state => state.mprDetails); 
    const {chosen} = useSelector(state => state.clientsGo);
    const {user_id, user_role, user_permissions} = useSelector(state => state.users);

    const [brandSearchField, setBrandSearchField] = useState('');

    console.log('unrelated: ', unrelated);
    console.log('mprDetails: ', mprDetails);
    console.log('filteredMprDetails: ', filteredMprDetails);
    console.log('brands: ', brands);
    console.log('filteredBrands: ', filteredBrands);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBrands(filter, unrelated));
        dispatch(getFilteredMprDetails(filter, unrelated));
    }, [filter, unrelated]);

    const finalBrands = brandSearchField 
        ? brands.filter(item => item.united.toLowerCase().includes(brandSearchField.toLowerCase()))
        : brands;
    console.log('finalBrands: ', finalBrands);

    const onChangeCheckBoxUnrelated = (event) => {
        if(chosen) return;
        dispatch(selectUnrelated(event.target.checked))
    };

    const onChangeSearchBrand = (event) => {
        setBrandSearchField(event.target.value)
    };

    console.log('brandSearchField / BrandsLeft: ', brandSearchField);
    console.log('unrelated / BrandsLeft: ', unrelated);

    return (
            <div className={classes.half}>  
                <Grid container spacing={1} display="flex" alignItems="center"> 
                {/* wrap="nowrap" */}
                   
                    <Grid item>
                        <BrandsDialog monitoringProjects={monitoringProjects} />
                    </Grid>

                    {filteredMprDetails.length>0 &&
                    <Grid item>
                        <FormControlLabel
                            className={classes.checkbox}
                            value="end"
                            control={<Checkbox checked={unrelated} onChange={onChangeCheckBoxUnrelated} color="secondary" />}
                            label="Непривязанные"
                            labelPlacement="end"
                        />
                    </Grid>}

                    {filteredMprDetails.length>0 && 
                    <Grid item>
                        <Search
                            className={classes.search} 
                            label={'Бренды'}
                            id={'brands'} 
                            searchChange={onChangeSearchBrand} 
                        />
                    </Grid>}

                    {filteredMprDetails.length>0 && user_role === 'Admin' &&
                    <Grid item>
                        <BrandsReport 
                            unrelated={unrelated}
                        />
                    </Grid>}
                    
                </Grid>

                {filteredMprDetails.length>0 &&
                <BrandsTableNew 
                    rows={finalBrands.length>0 ? finalBrands : []}
                />} 
            </div>
)};

export default BrandsLeft;
