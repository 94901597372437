import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    CREATE_CLIENTS_GO,
    RETRIEVE_CLIENTS_GO,
    RETRIEVE_CLIENTS_GO_BY_TYPE,
    GET_FILTERED_CLIENTS_GO,
    UPDATE_CLIENTS_GO,
    DELETE_CLIENTS_GO,
    SELECT_CLIENTS_GO,
    FILTER_CLIENTS_GO,
    SELECT_CHOSEN,
    GET_CLIENTS_GO_AND_BRANDS,
    ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS,
    SET_CLIENT_GO_FILTER,
  } from "../actions/types";
  
  const initialState = {
    chosen: false,
    clientsGo: [],
    filteredClientsGo: [],
    selectedClientsGo: [],
    clientsGoAndBrands: [],
    clientGoFilter: [],
  };
  
  function clientsGoReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }

      case SELECT_CHOSEN:
        return {
          ...state,
          chosen: payload,
        }
  
      case RETRIEVE_CLIENTS_GO:
        if(!payload) return state; 
        return {
                ...state,
                clientsGo: payload,
        }

      case RETRIEVE_CLIENTS_GO_BY_TYPE:
        if(!payload) return state; 
        return {
                ...state,
                clientsGo: payload,
        }
        
      case GET_FILTERED_CLIENTS_GO:
        if(!payload) return state; 
        return {
                ...state,
                filteredClientsGo: payload,
        }

      case FILTER_CLIENTS_GO:
        if(!payload) {
          return {
            ...state, 
            filteredClientsGo: state.clientsGo
          }
        }
        else {
          return {
            ...state,
            filteredClientsGo: state.clientsGo.filter(item => item.cg_name.toLowerCase().includes(payload.toLowerCase())),
          }
        }
        
      
      case SELECT_CLIENTS_GO:
        if(payload.length === 0) {
          return {
            ...state,
            selectedClientsGo: [],
          }
        }
        else {
          return {
            ...state,
            selectedClientsGo: payload
          }
        }
        
      case CREATE_CLIENTS_GO:
        return {
          ...state,
          clientsGo: state.clientsGo.concat(payload),
      } 
      
      case GET_CLIENTS_GO_AND_BRANDS:
        return {
          ...state,
          clientsGoAndBrands: payload,
      } 

      case ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS:
        return {
          ...state,
          clientsGoAndBrands: [...state.clientsGoAndBrands, payload],
      }
      
      case SET_CLIENT_GO_FILTER:
        return {
          ...state,
          clientGoFilter: payload,
      }
  
    //   case UPDATE_CLIENTS_GO:
    //     return clientsGo.map((clientsGo) => {
    //       if (clientsGo.id === payload.id) {
    //         return {
    //           ...clientsGo,
    //           ...payload,
    //         };
    //       } else {
    //         return clientsGo;
    //       }
    //     });
  
    //   case DELETE_CLIENTS_GO:
    //     return clientsGo.filter(({ id }) => id !== payload.id);
  
      default:
        return state;
    }
  };
  
  export default clientsGoReducer;