import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mactions`, { headers: authHeader() });
};

const get = (id) => {
    return axios.get(`/mactions/${id}`, { headers: authHeader() })
}
  
export default {
    getAll,
    get,
};