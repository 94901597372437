import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  imagelistitem: {
    marginRight: '10px;',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginBottom: '20px;',
  },
  dialog: {
    overflowY: 'unset',
  },
  button: {
    padding: 15,
  },
  link: {
    display: 'block',
    width: 333, 
    height: 250,
    padding: 15,
  }, 
}));


export default function MonitoringsImage(props) {
  const classes = useStyles();
  const {item, height, width, title, subtitle} = props;

  const [open, setOpen] = useState(false);   // open fullWidth image
  
  const handleDoubleClick = (e) => {
    setOpen((prev) => !prev);
  };

  console.log('PHOTO / MonitoringsImage: ', item);

  return !item.photo
    ? (
      // <Button style={{width: 333, height: 250}}>
        <a
          target="_blank" 
          href={item.passport}
          rel="noopener noreferrer"
          className={classes.link}
        >  
          <Typography variant="h5" align="center" className={classes.button}>
              Смотреть паспорт
              <Typography variant="subtitle2" align="center">
                {item.passport}
              </Typography>
            </Typography>
        </a> 
      // </Button>
      )
    : (
        <div
            className={classes.imagelistitem}
            // aria-owns={openPopover ? 'mouse-over-popover' : undefined}
            // aria-haspopup="true"
            onDoubleClick={handleDoubleClick}
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
        >
            <Dialog
                classes={{paper: classes.dialog}}
                aria-labelledby="simple-dialog-title" 
                open={open}
            >
              <img src={item.photo}/>
            </Dialog>
              <img
                src={item.photo}
                alt={'Фото мониторинга'}
                height={height ? height : 'auto'}
                width={width ? width : 'auto'}
                //onDoubleClick={handleDoubleClick}
                // onClick={() => handleClick()}
              />
        </div>
      )
}

// return (
//   <div
//       className={classes.imagelistitem}
//       // aria-owns={openPopover ? 'mouse-over-popover' : undefined}
//       // aria-haspopup="true"
//       onDoubleClick={handleDoubleClick}
//       // onMouseEnter={handlePopoverOpen}
//       // onMouseLeave={handlePopoverClose}
//   >
//       <Dialog
//           classes={{paper: classes.dialog}}
//           aria-labelledby="simple-dialog-title" 
//           open={open}
//       >
//             {item.photo === "https://via.placeholder.com/900x675"              
//               ? (
//                   <Box>
//                     <Link 
//                       href={item.passport} 
//                       target="_blank"
//                     >
//                       <Typography variant="h5" align="justify" className={classes.link}>
//                           Посмотреть ПАСПОРТ в новом окне
//                       </Typography>
//                     </Link>
//                   </Box>
//                   )
//               : (<img src={item.photo}/>)
//             }

//       </Dialog>
//     <>
//       <img
//         src={item.photo}
//         alt={'Фото мониторинга'}
//         height={height ? height : 'auto'}
//         width={width ? width : 'auto'}
//         //onDoubleClick={handleDoubleClick}
//         // onClick={() => handleClick()}
//       />
//       <h5>{title}</h5>
//       <p>{subtitle}</p>
//     </>
//   </div>      
// );