import {
  RETRIEVE_MTABS,
  } from "./types";
  
  import mtabsService from "../../services/mtabsService";
  
  export const retrieveMtabs = () => async (dispatch) => {
    try {
      const res = await mtabsService.getAll();
  
      dispatch({
        type: RETRIEVE_MTABS,
        payload: res.data.map(item => item.mt_name).sort(),
      });
      
    } catch (err) {
      console.log(err);
    }
  };