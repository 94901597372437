import {
  SET_USER,
  CREATE_USER,
  } from "./types";
  
import usersService from '../../services/usersService';
import store from '../store';


export const setUser = (user) => { 
  return {
    type: SET_USER,
    payload: user,
  }
};


  
  