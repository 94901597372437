import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { RenderCellExpand } from '../../utils/renderCellExpand';
import Search from '../search/search';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import MonitoringsImage from '../monitoringsImage/monitoringsImage';

import mprDetailsService from '../../services/mprDetailsService';
import { selectMprDetails } from '../../redux/actions/mprDetails';
import { to7digitals, takeUfId } from '../../utils/shared';
import {GET_FILTERED_MPR_DETAILS, GET_FILTERED_CLIENTS_GO} from '../../redux/actions/types';

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true },
  { field: 'clientGo', headerName: 'Client_Go', width: 100 },
  { field: 'address', headerName: 'Адрес масс. мониторинга', renderCell: RenderCellExpand, flex: 1 },
  { field: 'side', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'type', headerName: 'Тип констр.', sortable: false, width: 120 },
];

function geoMeter(geoIndex){
  let result;
  switch (geoIndex) {
    case 2:
      result = 50;
      break;
    case 3:
      result = 100;
      break;
    case 4:
      result = 200;
      break;
    case 5:
      result = 500;
      break;
    default:
      result = 40000000;
      break;
  }
  return result;
}

function latDegreeToMeter(lat){
  return lat * 111320;
}

function lngDegreeToMeter(lng, lat){
  return lng * 4007500 * Math.cos(lat * Math.PI / 180) / 360;
}


function makeRecord(item){
  return {
    id: item.mprd_id,
    clientGo: item.cg_name,
    address: item.mpd_address,
    side: item.mpd_side,
    type: item.mpd_unit_type,
  }
}

const useStyles = makeStyles((theme) => ({ 
    table: {
      minWidth: 650,
      minHeight: 500, 
      flexGrow: 1,
    },
    half: {
      maxHeight: '900px;',
      //overflow: 'hidden',
      maxWidth: '50vw',
      display: 'flex',
    },
  }));


export default function MonitoringsRightTable2(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {autoFlag, sideFlag, typeFlag, geoIndex, selectedIndex} = props;

    const {filter} = useSelector(state => state.shared);
    const {selectedClientsGo, filteredClientsGo} = useSelector(state => state.clientsGo);
    const {filteredMprDetails, selectedMprDetails} = useSelector(state => state.mprDetails);

    const [rows, setRows] = useState([]);
    const [viewedAddress, setViewedAddress] = useState();

    const [clientGo, setClientGo] = useState([]);
    const [search, setSearch] = useState(''); 

    useEffect(() => {
      mprDetailsService.filter(filter)
        .then((res) => {
          console.log('res: ', res.data);
          dispatch({
            type: GET_FILTERED_MPR_DETAILS,
            payload: res.data,
          });
          dispatch({
            type: GET_FILTERED_CLIENTS_GO,
            payload: [...new Set(res.data.map(item => item.cg_name))].sort(),
          }); 
        });      
    }, []);

    console.log('clientGo: ', clientGo);

    let rowsFinal;

    if(selectedClientsGo.length === 0) {
      rowsFinal = rows.map(item => makeRecord(item));
    }
    else if (selectedIndex === 2) {
      const photos = selectedClientsGo[0].photos.map(el => takeUfId(el)).filter(item => item !== 0);
      rowsFinal = rows.filter(item => photos.includes(item.mprdp_photo_assigned_uf_id)).map(item => makeRecord(item));
    }
    else {
      const rows1 = clientGo.length > 0
      ? rows.filter(item => clientGo.includes(item.cg_name))
      : rows;
    
      console.log('rows1: ', rows1);

      const rows2 = sideFlag === 'Yes' && selectedClientsGo.length>0 
      ? rows1.filter(item => selectedClientsGo[0].sideSimple.slice(0,1) === item.mpd_side.slice(0,1))
      : rows1;
    
      console.log('rows2: ', rows2);

      const rows3 = typeFlag === 'Yes' && selectedClientsGo.length>0 
      ? rows2.filter(item => selectedClientsGo[0].typeSimple === item.mpd_unit_type)
      : rows2;

      console.log('rows3: ', rows3);

      const rows4 = search
      ? rows3.filter(item => item.mpd_address.toLowerCase().includes(search.toLowerCase()))
      : rows3;

      console.log('rows4: ', rows4);

      const geoIn = (point1, point2, distanceLimit) => {  // ( selectedClientsGo[0], rows[i], geoMeter(geoIndex) )
        const lat_diff = Math.abs(point1.lat - point2.wod_suppl_lat);
        //console.log('lat diff: ', lat_diff);
    
      const lng_diff = Math.abs(point1.lng - point2.wod_suppl_lng);
      //console.log('lng diff: ', lng_diff);
    
      //console.log('distanceLimit: ', distanceLimit);
    
      const latDiffInMeters = latDegreeToMeter(lat_diff);
      //console.log('latDiffInMeters: ', latDiffInMeters);

      const lngDiffInMeters = lngDegreeToMeter(lng_diff, point1.lat);
      //console.log('lngDiffInMeters: ', lngDiffInMeters);
    
      return latDiffInMeters < distanceLimit && lngDiffInMeters < distanceLimit
        ? true
        : false;
      }

      const rows5 = geoIndex !== 1 && selectedClientsGo.length>0 
        ? rows4.filter(item => geoIn(selectedClientsGo[0], item, geoMeter(geoIndex) ))
        : rows4;

      console.log('rows5: ', rows5);
     
      rowsFinal = rows5.map(item => makeRecord(item)); 
    }
    
    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    // console.log('filter: ', filter);
  
    // console.log('search: ', search);
    // console.log('viewedAddress: ', viewedAddress);
    // console.log('selectedMprDetails: ', selectedMprDetails);
    // console.log('filteredMprDetails: ', filteredMprDetails);
    // console.log('selectedClientsGo / RIGHT TABLE: ', selectedClientsGo); 
   
    // console.log('sideFlag: ', sideFlag);
    // console.log('typeFlag: ', typeFlag);
    console.log('selectedClientsGo / RIGHT TABLE: ', selectedClientsGo);
    console.log('rowsFinal: / RIGHT TABLE', rowsFinal);   

  return (
    <div className={classes.half}>

      {/* {selectedClientsGo.length > 0 && */}
      <Grid container spacing={1} direction="column" alignItems="flex-start">

        <Grid container spacing={1} direction="row" alignItems="flex-start">  
        {autoFlag === 'No' &&
            <Grid item>    
                <MultiSelectWithAllOptionPlain
                    style={{
                      width: 300,
                      marginLeft: 10,
                    }}
                    size={'small'}
                    limitTags={2}
                    label={'Client_Go'}
                    items={filteredClientsGo}
                    value={clientGo}
                    selected={clientGo}
                    onChange={setClientGo}
                />
            </Grid>            
            }

            {autoFlag === 'No' &&
            <Grid item>
              <Search
                  label={'Адрес'}
                  size={'small'}
                  style={{
                    width: 200,
                    marginLeft: 10,
                  }}
                  id={'clientGO'}
                  value={search}
                  searchChange={onChangeSearch} 
              />
            </Grid>
            }

        </Grid>
          
            {filteredClientsGo.length>0 && selectedClientsGo.length>0 &&
            <Grid item>
              <DataGrid
                  className={classes.table}
                  autoHeight
                  localeText={ruRU.props.MuiDataGrid.localeText} 
                  rows={rowsFinal} 
                  columns={columns}
                  sortModel={[
                    {
                      field: 'address',
                      sort: 'asc',
                    },
                  ]}
                  pageSize={7} 
                  checkboxSelection 
                  disableColumnMenu
                  disableColumnSelector
                  disableSelectionOnClick
                  onRowClick={(e) => {
                    setViewedAddress(filteredMprDetails.find(jtem => jtem.mprd_id === e.row.id));
                  }}

                  onSelectionModelChange={(newSelection) => {
                      //setSelectedMprDetails(newSelection.selectionModel.map(item => filteredMprDetails.find(jtem => jtem.mprd_id === item)));
                      dispatch(selectMprDetails(newSelection.selectionModel.map(item => filteredMprDetails.find(jtem => jtem.mprd_id === item))))
                  }}
                  selectionModel={selectedMprDetails.map(item => item.mprd_id)} 
                  id={"data-grid-right"}
                />

            </Grid>
            }

            <Grid item>
              <MonitoringsImage
                  height={250}
                  width={333}  
                  item={viewedAddress 
                    ? {photo: "https://monitoring.graceoutdoor.ru/" + to7digitals(viewedAddress.mprd_mpr_id) + "/" + to7digitals(viewedAddress.mprdp_photo_assigned_uf_id) + "_" + to7digitals(viewedAddress.uf_rand) + "." + viewedAddress.uf_extension, passport: viewedAddress.mpd_photo_url}
                    : {photo: "https://via.placeholder.com/333x250", passport: ''}
                  }
                  //title={viewedAddress ? viewedAddress.cg_name + " / " + viewedAddress.mpd_address : null}
              /> 
            </Grid>      

        </Grid>
        {/* } */}
    </div>
  );
}