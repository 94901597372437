import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    SET_THE_FILTER,
    SET_THE_MODE,
    SHOW_SELECTED,
    SHOW_UNSELECTED,
    SHOW_DUPLICATES,
    SHOW_LIST,
    SHOW_AUTO,
    SHOW_SIDE,
    SHOW_TYPE,
    SHOW_GEO,
    SEARCH_STRING,
  } from "../actions/types";
  
  const initialState = {
    filter: {
      ctg_id: [],
      mprd_client_go: [],
      mpr_planned_date: [],
      mpd_mp_id: [],
      mpr_id: [],
      simple_mpr_id: [],
      mpd_city: [],
      mpd_unit_type: [],
    },
    mode: '',
    selectedIndex: 1,
    geoIndex: 1,    
    duplicatesFlag: 'No',
    listFlag: 'No',
    autoFlag: 'Yes',
    sideFlag: 'Yes',
    typeFlag: 'Yes',
    searchString: '',
  };
  
  function sharedReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
      
      case SET_THE_FILTER:
        if(!payload) return state; 
        return {
                ...state,
                filter: Object.assign({}, payload),
        }

      case SET_THE_MODE:
        if(!payload) return state; 
        return {
                ...state,
                mode: payload,
        }

      case SHOW_SELECTED: 
        if(!payload) return state;
        return {
                ...state,
                selectedIndex: payload,                
        }
        
      case SHOW_DUPLICATES:
      if(!payload) return state;
      return {
              ...state,
              duplicatesFlag: payload,
      }

    case SHOW_LIST:
      if(!payload) return state;
      return {
              ...state,
              listFlag: payload,
      }

    case SHOW_AUTO:
      if(!payload) return state;
      return {
              ...state,
              autoFlag: payload,
      }

    case SHOW_SIDE:
      if(!payload) return state;
      return {
              ...state,
              sideFlag: payload,
      }

    case SHOW_TYPE:
      if(!payload) return state;
      return {
              ...state,
              typeFlag: payload,
      }

    case SHOW_GEO: 
      if(!payload) return state;
      return {
              ...state,
              geoIndex: payload,                
      }

    case SEARCH_STRING:
      if(!payload) return state;
      return {
              ...state,
              searchString: payload,
      }

      default:
        return state;
    }
  };
  
  export default sharedReducer;
