import React, {useState} from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import {removeBindedFromFilteredMpDetailsWithoutBrand} from '../../redux/actions/mpDetails';
import {bindPhotosToTheClientsGo} from '../../redux/actions/mprDetails';
import {saveLog} from '../../redux/actions/mlogs';

import Image from '../image/image';

import {_baseApiUrl} from '../../redux/constants';
import { addBrandToClientsGoAndBrands } from '../../redux/actions/clientsGo';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexDirection: 'row',
      fleWrap: 'nowrap',
      maxHeight: '900px;',
    },
  success: {
      flexBasis: '75%',
      padding: '14px;',
      marginRight: '0px;',
      color: '#fff',
      // marginBottom: '10px;',
  },
  cancel: {
    flexBasis: '25%',
    padding: '14px;',
    color: '#fff',
    // backgroundColor: theme.palette.secondary.main,
  },
  info: {
    padding: '14px;'
  },
  photoContainer: {
      marginTop: '8px;',
  },
  typography: {
      padding: theme.spacing(2),
  },
}));

const filter = createFilterOptions();

export default function PhotosActions(props) {
  const {options, mpDetail, setPrevMpDetail, setCanceled, defaultValue, setIndex, prevMpDetail, isCanceled, setLoaded} = props;

  const classes = useStyles();
  const {mpDetailsWithoutBrand} = useSelector(state => state.mpDetails);
  const {user_id, user_role, user_permissions} = useSelector(state => state.users)

  const dispatch = useDispatch();

  const [value, setValue] = useState(null); 
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    cg_name: '',
    // year: '',
  });

  // console.log('value: ', value);
  // console.log('dialogValue: ', dialogValue);
  // console.log('open: ', open);

  const handleClose = () => {
    setDialogValue({
      cg_name: '',
    //   year: '',
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => { 
    event.preventDefault();

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'photos-ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS')) {
      alert('В доступе отказано. Обратитесь к администратору');
      return;   
    }
    
    setValue({
      cg_name: dialogValue.cg_name,
    });

    handleClose(); 
  };

  const handleSetBrand = (event) => {

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'photos-BIND_PHOTO_TO_THE_CLIENTS_GO')) {
      alert('В доступе отказано. Обратитесь к администратору');
      return
    }
    
    let newBrand;
    let newClientGo;

    if (value === null) {      
      return
    } 
    else if (value.ctg_id) {
      newBrand = value.cg_name;
      newClientGo = value.cg_id;
    }
    else {
      newBrand = value.cg_name;
      newClientGo = 0;
    }

    console.log('value: ', value);
    console.log('newBrand: ', newBrand);
    console.log('newClientGo: ', newClientGo);
    console.log('options / freeSolo: ', options);
    
    if(!options.map(item => item.cg_name.toLowerCase()).includes(newBrand.toLowerCase())) {
      const newItem = {
        id: options.length,
        cg_name: newBrand,
        united: newBrand,
      }      
      dispatch(addBrandToClientsGoAndBrands(newItem));
      options.push(newItem);
    } 

    const newMprDetail = {
      mprd_id: mpDetail.mprd_id,
      mprd_brand: newBrand,
      mprd_client_go: +newClientGo,
    };
    console.log('newMprDetail: ', newMprDetail);

    const mLog = {
      ml_mu_id: user_id,
      ml_mt_id: 1,
      ml_ma_id: 2, 
      ml_qty: 1,
      ml_comments: JSON.stringify(newMprDetail),
    }

    dispatch(bindPhotosToTheClientsGo(newMprDetail.mprd_id, newMprDetail));
    dispatch(removeBindedFromFilteredMpDetailsWithoutBrand(newMprDetail.mprd_id));
    dispatch(saveLog(mLog))
      .then(data => console.log('ЛОГ СОХРАНЕН:', data))
      .catch(e => {
        console.log(e);
      });

    setPrevMpDetail(mpDetail);
    setCanceled(false);
  }
 
  return (
    <>
       <Grid container spacing={2}>
         <Grid item>
            <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                // console.log(newValue);
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpen(true);
                  setDialogValue({
                    cg_name: newValue,
                    // year: '',
                  });
                });
              } else if (newValue && newValue.inputValue) {
                toggleOpen(true);
                setDialogValue({
                    cg_name: newValue.inputValue,
                //   year: '',
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  united: `Добавить "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={options}
            getOptionSelected={(option, value) => value.cg_name === option.cg_name}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
            //   return option.united;
              return option.cg_name;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option.united}
            style={{ width: 500 }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Выберите название бренда" variant="outlined" color="secondary"/>
            )}
          />

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Добавить новый бренд</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Не нашли нужный бренд? добавьте его!
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="cg_name"
                  value={dialogValue.cg_name}
                  onChange={(event) => setDialogValue({ ...dialogValue, cg_name: event.target.value })}
                  label="Название бренда"
                  type="text"
                />
                {/* <TextField
                  margin="dense"
                  id="cg_name"
                  value={dialogValue.year}
                  onChange={(event) => setDialogValue({ ...dialogValue, year: event.target.value })}
                  label="year"
                  type="number"
                /> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Отмена
                </Button>
                <Button type="submit" color="secondary">
                  Добавить
                </Button>
              </DialogActions>
            </form>
          </Dialog>
         </Grid>

          <Grid item>
            <Button 
                variant="contained" 
                color="secondary"
                size="large"
                className={classes.success}
                onClick={handleSetBrand}
            >
              Привязать
            </Button>
          </Grid>

          <Grid item>
            <Button 
                variant="contained" 
                color="primary"
                size="large"
                className={classes.cancel}
                onClick={() => setCanceled(true)}
            > 
                Вернуться
            </Button>
          </Grid> 

          <Grid item>
            <Button 
                variant="outlined" 
                color="default"
                size="large"
                className={classes.info}
            > 
                Фотографий без бренда: {mpDetailsWithoutBrand.length}
            </Button>
          </Grid>
            
         
      </Grid>
    </>
  );
}