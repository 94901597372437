import {
  RETRIEVE_MACTIONS,
  } from "./types";
  
  import mactionsService from "../../services/mactionsService";
  
  export const retrieveMactions = () => async (dispatch) => {
    try {
      const res = await mactionsService.getAll();
  
      dispatch({
        type: RETRIEVE_MACTIONS,
        payload: res.data.map(item => item.ma_name).sort(),
      });
      
    } catch (err) {
      console.log(err);
    }
  };