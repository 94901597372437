import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    GET_MONTHS_FROM_MP_REPORTS,
    GET_FILTERED_MP_REPORTS,
  } from "../actions/types";
  
const initialState = {
    filteredMpReports: [],
    monthsFromMpReports: [],
};
  
function mpReportsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }

      case GET_MONTHS_FROM_MP_REPORTS:
        if(!payload) return state;
        return {
                ...state,
                monthsFromMpReports: payload,
        }      
      
      case GET_FILTERED_MP_REPORTS:
        if(!payload) return state;
        return {
                ...state,
                filteredMpReports: payload,
        }
            
      default:
        return state;
  };
 }
  
export default mpReportsReducer;