import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

import {selectClientsGo} from '../../redux/actions/clientsGo';
import {getBrandsRelatedToTheClientGo} from '../../redux/actions/mprDetails';
import { SELECT_CLIENTS_GO, GET_BRANDS_RELATED } from '../../redux/actions/types';

const columns = [
  { field: 'cg_id', headerName: 'id', width: 70, hide: true },
  { field: 'united', headerName: 'Название Client_Go', flex: 1 },
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    table: {
        flexGrow: 1,
    },
  }));

export default function ClientsGoTableNew(props) { 
    const classes = useStyles();

    const {rows} = props;
    console.log('rows: ', rows);

    const {filter} = useSelector(state => state.shared);
    const {chosen, selectedClientsGo} = useSelector(state => state.clientsGo);
    console.log('chosen: ', chosen);
    console.log('selectedClientsGo from table: ', selectedClientsGo);

    const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <DataGrid
        className={classes.table}
        autoHeight
        localeText={ruRU.props.MuiDataGrid.localeText} 
        rows={rows} 
        columns={columns} 
        pageSize={5}
        disableColumnMenu
        onSelectionModelChange={(newSelection) => {
            console.log('newSelection: ', newSelection);
            dispatch({
              type: SELECT_CLIENTS_GO,
              payload: newSelection.selectionModel
            });
            console.log('chosen:', chosen);
            if(chosen) {
              dispatch(getBrandsRelatedToTheClientGo(filter, newSelection.selectionModel[0]));
            }
        }}
        selectionModel={selectedClientsGo}
        />
    </div>
  );
}