import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    SAVE_LOG,
  } from "../actions/types";
  
  const initialState = {
    mlogs: [],
  };
  
  function mlogsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }

      case SAVE_LOG:
        return {
          ...state,
          mlogs: [...state.mlogs, payload],
        }
  
      default:
        return state;
    }
  };
  
  export default mlogsReducer;