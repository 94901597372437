import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';  

import { makeStyles } from '@material-ui/core/styles';
import MonitoringsAutoTable from '../monitoringsTables/monitoringsAutoTable';
import MonitoringsLeftTable from '../monitoringsTables/monitoringsLeftTable';
import MonitoringsRightTable from '../monitoringsTables/monitoringsRightTable';
import MonitoringsRightTable2 from '../monitoringsTables/monitoringsRightTable2';

import mprDetailsService from '../../services/mprDetailsService';
import {GET_FILTERED_MPR_DETAILS, GET_FILTERED_CLIENTS_GO} from '../../redux/actions/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  twoTables: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row nowrap',
    height: '100%;'    
  },
  divider: {
    width: 14,
  },
}));

const distance = (point1, point2) => Number(point1.lat) && Number(point2.wod_suppl_lat) && Number(point1.lng) && Number(point2.wod_suppl_lng)
	? Math.sqrt(Math.pow(Number(point1.lat) - Number(point2.wod_suppl_lat), 2) + Math.pow(Number(point1.lng) - Number(point2.wod_suppl_lng), 2))
  : console.log("Ошибка данных: point1: ", point1, ' point2: ', point2);

const theSameSideAndType = (side1,side2,type1,type2) => side1.slice(0,1) === side2.slice(0,1) && type1 === type2 ? true : false;

function addMonitoring(obj, arr){
  let minimum = 100000;
  let index = 0;

  for (let i = 0;i<arr.length;i++){
    if(distance(obj,arr[i]) < minimum && obj.mpd_id !== arr[i].mpd_id && theSameSideAndType(obj.sideSimple, arr[i].mpd_side, obj.typeSimple, arr[i].mpd_unit_type)) {
      minimum = distance(obj,arr[i]);
      index = i;
    }
  };
  
  return minimum === 100000 
    ? Object.assign({}, obj, { 
        mid: 1,
        address: 'НЕТ ВАРИАНТОВ',
      })
    : Object.assign({}, obj, {
      mid: arr[index].mprd_id,
      address: arr[index].mpd_address,
      side: arr[index].mpd_side,
      type: arr[index].mpd_unit_type,
      clientGo: arr[index].cg_name ? arr[index].cg_name : arr[index].mprd_brand ? arr[index].mprd_brand : '',
      wod_suppl_lat_simple: arr[index].wod_suppl_lat,
      wod_suppl_lng_simple: arr[index].wod_suppl_lng,
      unit_coord_lat: arr[index].unit_coord_lat,
      unit_coord_lng: arr[index].unit_coord_lng,
      mprdp_photo_assigned_uf_id: arr[index].mprdp_photo_assigned_uf_id,
      uf_rand: arr[index].uf_rand,
      uf_extension: arr[index].uf_extension,
      mprd_mpr_id: arr[index].mprd_mpr_id,
      mprdp_id: arr[index].mprdp_id,
  })
}


export default function MonitoringsContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const {filter, mode, autoFlag, selectedIndex, sideFlag, typeFlag, geoIndex} = useSelector(state => state.shared);
  const {filteredMpDetailsSimple} = useSelector(state => state.mpDetails);
  const { filteredMprDetails } = useSelector(state => state.mprDetails);

  const [errorCounter, setErrorCounter] = useState(null);

  // useEffect(() => {
  //   mprDetailsService.filter(filter)
  //     .then((res) => {
  //       console.log('res: ', res.data);
  //       dispatch({
  //         type: GET_FILTERED_MPR_DETAILS,
  //         payload: res.data,
  //       });
  //       dispatch({
  //         type: GET_FILTERED_CLIENTS_GO,
  //         payload: [...new Set(res.data.map(item => item.cg_name))].sort(),
  //       }); 
  //     });      
  // }, []);

  const filteredMpDetailsSimpleChecked = filteredMpDetailsSimple.filter(item => item.lat && +item.lat !== 0 && item.lng && +item.lng !== 0);
  console.log('filteredMpDetailsSimpleChecked / CONTAINER: ', filteredMpDetailsSimpleChecked);
  if(filteredMpDetailsSimpleChecked.length !== filteredMpDetailsSimple.length) {
    console.log('problemsSimple: ', filteredMpDetailsSimple.filter(item => !item.lat || +item.lat == 0 || !item.lng || +item.lng == 0));
    if(!errorCounter) {
      alert('В адресках ОБЫЧНОГО мониторинга отсутствуют некоторые координаты');
      setErrorCounter(1);
    };
  }; 

  const filteredMprDetailsChecked = filteredMprDetails.filter(item => +item.wod_suppl_lat !== 0 && +item.wod_suppl_lng !== 0);
  console.log('filteredMprDetailsChecked / CONTAINER: ', filteredMprDetailsChecked);
  if(filteredMprDetailsChecked.length !== filteredMprDetails.length) {
    console.log('problems: ', filteredMprDetails.filter(item => +item.wod_suppl_lat == 0 || +item.wod_suppl_lng == 0));
    if(!errorCounter || errorCounter === 1) {
      alert('В адресках МАСС мониторинга отсутствуют некоторые координаты');
      setErrorCounter(2);
    };
  };

  let rows = []; 
  if(filteredMprDetailsChecked.length>0 && filteredMpDetailsSimpleChecked.length>0){
    for (let i = 0; i < filteredMpDetailsSimpleChecked.length; i++){
      rows.push(addMonitoring(filteredMpDetailsSimpleChecked[i], filteredMprDetailsChecked));
      //console.log('rows length: ', rows.length)
    }
  }

  console.log('mode / CONTAINER: ', mode);
  console.log('rows / CONTAINER: ', rows);
  console.log('filteredMprDetails / CONTAINER: ', filteredMprDetails);
  console.log('filteredMpDetailsSimple / CONTAINER: ', filteredMpDetailsSimple);
  

  return autoFlag === 'Yes'
    ? (
          <div className={classes.root}>
            {rows.length>0 &&
            <MonitoringsAutoTable 
              rows={rows}
              selectedIndex={selectedIndex}
            />}            
          </div>
      )
    : (
      <div className={classes.twoTables}>
            <MonitoringsLeftTable
              rows={filteredMpDetailsSimple} 
              selectedIndex={selectedIndex}
            />           
            <div className={classes.divider}></div>
            < MonitoringsRightTable
              rows={filteredMprDetails}
              autoFlag={autoFlag}
              sideFlag={sideFlag}
              typeFlag={typeFlag}
              geoIndex={geoIndex}
              selectedIndex={selectedIndex}
            />
        </div>
    )
}