import React, {useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import NavTabs from './components/navTabs/navTabs';
import SignIn from './pages/sign-in/sign-in';

import './App.scss';

// function getToken() {
//   const tokenString = sessionStorage.getItem('token');
//   const userToken = JSON.parse(tokenString);
//       return userToken?.token
// };

function App() {
  const [token, setToken ] = useState();
  // console.log('token from App', token);

  return !token 
     ? ( <div>
            <CssBaseline />
            <SignIn setToken={setToken} />
        </div>
        )
      : (
          <div>
            <CssBaseline />
            <NavTabs setToken={setToken}/>
          </div>
        )
}

export default App;