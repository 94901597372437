import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_MTABS,
  } from "../actions/types";
  
  const initialState = {    
    mtabs: [],
  };
  
  function mtabsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_MTABS:
        if(!payload) return state; 
        return {
                ...state,
                mtabs: payload,
        }

      default:
        return state;
    }
  };
  
  export default mtabsReducer;