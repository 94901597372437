import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import AdminUsers from '../../components/adminUsers/adminUsers';
import AdminEspar from '../../components/adminEspar/adminEspar';
import AdminReports from '../../components/adminReports/adminReports';

import { retrieveMonitoringProjects } from '../../redux/actions/monitoringProjects';
import { getFilteredMpReports, getMonthsFromMpReports } from '../../redux/actions/mpReports';
import { getAllCitiesFromMpDetails } from '../../redux/actions/mpDetails'; 



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // style={{display: 'flex'}}
      // style={{width: '600px'}}
      {...other}
    >
      {value === index && (
        // <Container p={2}>
        //   {children}
        // </Container>
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.element,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 800,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function AdminPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(retrieveMonitoringProjects());
    dispatch(getFilteredMpReports());
    dispatch(getMonthsFromMpReports());
    dispatch(getAllCitiesFromMpDetails());    
  }, [])

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="standard"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Пользователи" {...a11yProps(0)} />
        <Tab label="Отчеты" {...a11yProps(1)} />
        <Tab label="Эспар данные" {...a11yProps(2)} />

      </Tabs>
      <TabPanel value={value} index={0}>
        <AdminUsers />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminReports />
      </TabPanel>
      <TabPanel value={value} index={2}> 
        <AdminEspar/>
      </TabPanel>
      
    </div>
  );
}
