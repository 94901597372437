import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mtabs`, { headers: authHeader() });
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mtabs/${id}`, { headers: authHeader() })
}

export default {
    getAll,
    get,
};