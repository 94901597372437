import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { lightBlue } from '@material-ui/core/colors'; 
import Grid from '@material-ui/core/Grid';
import MultiSelectWithAllOption from '../multiSelectWithAllOption/multiSelectWithAllOption';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';

import { getFilteredClientsGo } from '../../redux/actions/clientsGo';
import { getFilteredMpReports, getMonthsFromMpReports } from '../../redux/actions/mpReports';
import { getFilteredMpDetails, getFilteredCitiesFromMpDetails, getFilteredUnitTypesFromMpDetails} from '../../redux/actions/mpDetails';
import { setTheFilter} from '../../redux/actions/shared';

import {_baseApiUrl} from '../../redux/constants';


const useStyles = makeStyles((theme) => ({  
    root: {
      display: 'flex',
      flexDirection: 'row', 
      fleWrap: 'nowrap',
    },      
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
        // marginBottom: '10px;',
    },
    export: {
      color: 'black',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginBottom: '20px;',
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    title: {
      padding: '10px;',
      paddingLeft: '24px;',
    },
    dialogcontent: {
      padding: '8px 24px 24px 24px',
    },
    dialogcontainer: {
      marginBottom: '10px',
    },

  }));

  function makeClientGoType(item){
    return {
      id: item.ctg_id,
      united: item.ctg_name,
    }
  }

export default function MaketsDialog() {
  const classes = useStyles();
  
  const { clientsTypesGo } = useSelector(state => state.clientsTypesGo);
  const { filteredClientsGo } = useSelector(state => state.clientsGo);
  const { filteredMpReports, monthsFromMpReports } = useSelector(state => state.mpReports);
  const { filteredCities, filteredUnitTypes } = useSelector(state => state.mpDetails);
  
  const {user_role, user_permissions} = useSelector(state => state.users);

  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);
  
  const [ctg_id, setClientsTypesGoId] = useState(); 
  const [clientGo, setClientGo] = useState([]);
  const [month, setMonth] = useState([]);
  // const [mpd_mp_id, setMpdMpId] = useState([]);      // monitoring_projects
  const [mpr_id, setMprId] = useState([]);              // mp_reports
  const [mpd_city, setMpdCity] = useState([]);          // mp_details
  const [mpd_unit_type, setMpdUnitType] = useState([]); // mp_details


  console.log('ctg_id: ', ctg_id);
  console.log('filteredClientsGo: ', filteredClientsGo);
  console.log('clientGo: ', clientGo);
  console.log('filteredMpReports: ', filteredMpReports);
  console.log('clientGo JSON: ', JSON.stringify(clientGo).length);

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilter = function(dtoObj, property, action){
    return function(newValue){
      dtoObj[property] = newValue;
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const loadPhotos = () => {
    const filter = {
      mprd_client_go: clientGo.length === filteredClientsGo.length ? [] : clientGo,
      mpr_planned_date: month,
      mpr_id: mpr_id,
      mpd_city: mpd_city,
      mpd_unit_type: mpd_unit_type,
    };

    dispatch(getFilteredMpDetails(filter));
    dispatch(setTheFilter(filter));

    handleClose();
  }

  return (
    <div>
      <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>

        <Grid item>
        <Tooltip title="Тип клиента -> Клиент -> Месяц -> Отчет -> Город -> Тип констр.">
          <Button
              variant="contained"
              size="large"
              color="secondary" 
              className={classes.success}
              onClick={handleClickOpen}
          >
              Фильтр 1
          </Button>
          </Tooltip>
        </Grid>


        <Grid item>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.title}>Фильтр</DialogTitle>
          
          <DialogContent className={classes.dialogcontent}>

              <form className={classes.container}>
                <Grid container spacing={1} display="flex" direction="column"  wrap="nowrap" alignItems="baseline">

                  {clientsTypesGo.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOption
                      style={{
                        width: 550,
                      }}
                      label={'Client_Types_Go'}
                      items={clientsTypesGo.map(item => makeClientGoType(item))}
                      limitTags={5}
                      value={ctg_id}
                      selected={ctg_id}
                      onChange={setClientsTypesGoId} 
                      fn={getFilter(
                        {
                          cg_ctg_id: [],
                        },
                        'cg_ctg_id',
                        getFilteredClientsGo,
                        dispatch
                      )}
                    />
                  </Grid>
                  }

                  {filteredClientsGo.length>0 && 
                  <Grid item>
                    <MultiSelectWithAllOption
                      style={{
                        width: 550,
                      }}
                      label={'Client_Go'}
                      items={filteredClientsGo}
                      limitTags={5}
                      value={clientGo}
                      selected={clientGo}
                      onChange={setClientGo} 
                      fn={getFilter(
                        {
                          mprd_client_go: [],
                        },
                        'mprd_client_go',
                        getMonthsFromMpReports,
                        dispatch
                      )}
                    />
                  </Grid>}

                  {filteredClientsGo.length>0 && monthsFromMpReports.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                      style={{
                        width: 550,
                      }} 
                      label={'Месяц'}
                      items={ user_role === 'Admin' ? monthsFromMpReports : monthsFromMpReports.filter(item => user_permissions.months.includes(item))}
                      value={month}
                      selected={month}
                      onChange={setMonth}
                      fn={getFilter(
                        {
                          mprd_client_go: clientGo.length === filteredClientsGo.length ? [] : clientGo,
                          mpr_planned_date: [],
                        },
                        'mpr_planned_date',
                        getFilteredMpReports
                      )}
                    />
                  </Grid>}
                  

                  {filteredClientsGo.length>0 && monthsFromMpReports.length>0 && filteredMpReports.length>0 &&
                  <Grid item>
                          <MultiSelectWithAllOptionPlain
                              style={{
                                width: 550,
                              }}
                              label={'Отчеты мониторинга'}
                              items={filteredMpReports}
                              value={mpr_id}
                              selected={mpr_id}
                              onChange={setMprId}
                              fn={getFilter(
                                {
                                  mprd_client_go: clientGo.length === filteredClientsGo.length ? [] : clientGo,                                
                                  mpr_planned_date: month,
                                  mpr_id: [],
                                },
                                'mpr_id',
                                getFilteredCitiesFromMpDetails
                              )} 
                          />
                  </Grid>}
                  

                  {filteredClientsGo.length>0 && monthsFromMpReports.length>0 && filteredMpReports.length>0 && filteredCities.length>0 &&
                  <Grid item>
                          <MultiSelectWithAllOptionPlain
                              style={{
                                width: 550,
                              }}
                              label={'Город'}
                              items={filteredCities}
                              value={mpd_city}
                              selected={mpd_city}
                              onChange={setMpdCity}
                              fn={getFilter(
                                {
                                  mprd_client_go: clientGo.length === filteredClientsGo.length ? [] : clientGo,                                
                                  mpr_planned_date: month,
                                  mpr_id: mpr_id,
                                  mpd_city: mpd_city,                                
                                },
                                'mpd_city',
                                getFilteredUnitTypesFromMpDetails
                              )}
                          />
                  </Grid>}

                  {filteredClientsGo.length>0 && monthsFromMpReports.length>0 && filteredMpReports.length>0 && filteredCities.length>0 && filteredUnitTypes.length>0 &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                        style={{
                          width: 550,
                        }} 
                        label={'Типы конструкций'}
                        items={filteredUnitTypes}
                        value={mpd_unit_type}
                        selected={mpd_unit_type}
                        onChange={setMpdUnitType} 
                    />
                  </Grid>}
                
                </Grid>
              </form>
          </DialogContent>
          
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Отмена
            </Button>

            {mpd_unit_type.length>0 &&
            <Button 
              color="secondary"
              onClick={loadPhotos}
            >
              Загрузить фотографии
            </Button>}
            
          </DialogActions>
        </Dialog>
      </Grid>

    </Grid>      
    </div>
  );
}