import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const copyFile = (data) => {
        return axios.post(_baseApiUrl + `/files/copy_file`, data, { headers: authHeader() });
}

export default {
    copyFile,
};