import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import mprDetailsService from '../../services/mprDetailsService';

export default function BrandsReport(props) {
    const dispatch = useDispatch();

    const {unrelated} = props;
    const {filter} = useSelector(state => state.shared);

    const exportExcel = () => {

        console.log('unrelated / REPORT:', unrelated);
        console.log('filter / REPORT:', filter);

        mprDetailsService.filter(filter)
            .then((res) => {

                const dataForReport = unrelated 
                ? res.data.filter(item => item.mprd_client_go === 0 && item.mprd_brand !== null) 
                : res.data.filter(item => item.mprd_client_go !== 0);
    
            let wb = new ExcelJS.Workbook();
            let workbookName = "WO_BrandsReport.xlsx";
            let worksheetName = `Data`;
            let ws = wb.addWorksheet(worksheetName);
    
            ws.columns = [
                {
                    key: "mprd_brand", 
                    header: "Бренд", 
                    width: 25 
                },
                {
                    key: "cg_name", 
                    header: "Client_Go", 
                    width: 50 
                },
                {
                    key: "ctg_name", 
                    header: "Client_Tupe_Go", 
                    width: 50
                }, 
            ];
    
            ws.getRow(1).alignment = { 
                vertical: 'top',
                wrapText: true
            };
        
            ws.getRow(1).font = {
                bold: true,
                color: { argb: "ffffff" },
            };
        
            ws.getRow(1).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00b050" }
            };
        
            for (let i = 0; i< dataForReport.length; i++){
    
                ws.addRow({
                    mprd_brand: dataForReport[i].mprd_brand,
                    cg_name: dataForReport[i].cg_name ? dataForReport[i].cg_name : '',
                    ctg_name: dataForReport[i].ctg_name ? dataForReport[i].ctg_name : '',
                });
            
                ws.getRow(i+2).alignment = { 
                    vertical: 'top',
                    horizontal: 'left',
                };
            };
    
            wb.xlsx.writeBuffer()
            .then(function(buffer){
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),  
                    workbookName
                );
            });
        })        
    };  

    return (
        <div>
            <Button
                variant="contained"
                color="default"
                startIcon={<GetAppIcon />}
                onClick={exportExcel}
            >
                Отчет    
            </Button>
    </div>
    );
}        
