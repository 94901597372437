import {
    RETRIEVE_CLIENTS_TYPES_GO,
  } from "./types";
  
  import clientsTypesGoService from "../../services/clientsTypesGoService";

  // function _transformClientGo(clientGo){
  //   return Object.assign({}, clientGo, {id: clientGo.cg_id}, {united: clientGo.cg_name.concat(' / ', clientGo.ctg_name) });    
  // }
  
  export const retrieveClientsTypesGo = () => async (dispatch) => {
    try {
      const res = await clientsTypesGoService.getAll();
  
      dispatch({
        type: RETRIEVE_CLIENTS_TYPES_GO,
        payload: res.data,

        // payload: res.data.map(item => _transformClientGo(item)),
      });
    } catch (err) {
      console.log(err);
    }
  };