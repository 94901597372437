//массив периодов формата YYYY-MM
// n - число месяцев

export function getMonthsList(n){
  let monthsList = [];
  let i = 0;  
  let year = new Date().getFullYear();  
  let month = new Date().getMonth() + 1;
  
  while (i<n) {
   if(month<10) {
     monthsList.push(`${year}-0${month}`);
   } 
   else {
    monthsList.push(`${year}-${month}`);
   }
  
   month--;
  
   if(month === 0) {
    month = 12;
    year--;
   }
   
   i++;	
  }
  
  return monthsList;
}



//случайное целое в диапазоне включая крайние значения
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //Максимум и минимум включаются
}


// удаление дублей из массива по имеющемуся свойству
export function removeDuplicates(arr) {

  const result = [];
  const duplicatesIndices = [];

  // Перебираем каждый элемент в исходном массиве
  arr.forEach((current, index) => {
  
      if (duplicatesIndices.includes(index)) return;
  
      result.push(current);
  
      // Сравниваем каждый элемент в массиве после текущего
      for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
      
          const comparison = arr[comparisonIndex];
          const currentKeys = Object.keys(current);
          const comparisonKeys = Object.keys(comparison);
          
          // Проверяем длину массивов
          if (currentKeys.length !== comparisonKeys.length) continue;
          
          // Проверяем значение ключей
          const currentKeysString = currentKeys.sort().join("").toLowerCase();
          const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
          if (currentKeysString !== comparisonKeysString) continue;
          
          // Проверяем индексы ключей
          let valuesEqual = true;
          for (let i = 0; i < currentKeys.length; i++) {
              const key = currentKeys[i];
              if ( current[key] !== comparison[key] ) {
                  valuesEqual = false;
                  break;
              }
          }
          if (valuesEqual) duplicatesIndices.push(comparisonIndex);
          
      } // Конец цикла
  });  
  return result;
}


export const pathToSmallPhoto = (obj) => {

  // add 0 to 7 letters's string: 369 => 0000369 
  const to7digitals = (str) => {
    if (typeof str !== 'string') str = String(str);
    while(str.length<7) str = '0' + str; 
    return str
  };

  const path = "https://monitoring.graceoutdoor.ru/" + to7digitals(obj.mpr_id) + "/" + to7digitals(obj.mprdp_photo_assigned_uf_id) + "_" + to7digitals(obj.uf_rand) + "_900." + obj.uf_extension;
  
  return path
}



export const pathToPhoto = (obj) => {

  // add 0 to 7 letters's string: 369 => 0000369 
  const to7digitals = (str) => {
    if (typeof str !== 'string') str = String(str);
    while(str.length<7) str = '0' + str; 
    return str
  };

  const path = "https://monitoring.graceoutdoor.ru/" + to7digitals(obj.mpr_id) + "/" + to7digitals(obj.mprdp_photo_assigned_uf_id) + "_" + to7digitals(obj.uf_rand) + "." + obj.uf_extension;
  
  return path
}

//take uf_id (mprdp_photo_assigned_uf_id) from path
export const takeUfId = (path) => {
  let re1 = /\/[0-9]+_/g;
  let re2 = /\_/g;
  return +path.substring(path.search(re1)+1, path.search(re2));
};


// add 0 to 7 letters's string: 369 => 0000369 
export const to7digitals = (str) => {
    if (typeof str !== 'string') str = String(str);
    while(str.length<7) str = '0' + str; 
    return str
};


// check if filter is in initial state
export const isInitialState = (filter) => {
  let flag = true;
  for (let key in filter) { if(filter[key].length>0) flag = false; }
    return flag;
}

// create base64 array form url array
const createBlob = (response) => response.blob();

const fromBlobToBase64 = (blob) =>
  new Promise((res) => {
    var reader = new FileReader();
    reader.onloadend = () => res(reader.result);
    reader.readAsDataURL(blob);
  });

const createBase64ImageFromURL = (url) =>
  fetch(url)
    .then(createBlob)
    .then((blob) => fromBlobToBase64(blob));

const createBase64ImagesFromURLArray = (arr) => {
    const array = [];
//   const map = new Map();
  const promiseArray = arr.reduce((promises, currentUrl) => {
    // if (!map.has(currentUrl)) {
      var promise = createBase64ImageFromURL(currentUrl);
    //   promise.then((base64) => map.set(currentUrl, base64));
      promise.then((base64) => promises.push(base64));
    //   promises.push(promise);
    // }
    return promises;
  }, []);

  return Promise.all(promiseArray).then(() => arr);
};

//check if the image is loaded
export async function loadImage(url, elem) {
  return new Promise((resolve, reject) => {
    elem.onload = () => resolve(elem);
    elem.onerror = reject;
    elem.src = url;
  });
}

//check if all the images are loaded
export function imagesLoaded() {
  const imgElements = document.querySelectorAll("img");
  for (const img of imgElements) {
    if (!img.complete) {
      return false;
    }
  }
  return true;
}
