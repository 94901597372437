import {
    RETRIEVE_MP_COMMUNICATIONS,    
    CREATE_MP_COMMUNICATIONS,
    BIND_MP_COMMUNICATION_TO_THE_PHOTO,
  } from "./types";
  
  import mpCommunicationsService from "../../services/mpCommunicationsService";
  import store from '../store';


  export const retrieveMpCommunications = () => async (dispatch) => {
    try {
      const res = await mpCommunicationsService.getAll();
  
      dispatch({
        type: RETRIEVE_MP_COMMUNICATIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const createMpCommunication = (newCommunication) => async (dispatch) => {
    try {
      const res = await mpCommunicationsService.create(newCommunication);
  
      dispatch({
        type: CREATE_MP_COMMUNICATIONS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // export const bindMpCommunicationToThePhoto = (id) => async (dispatch) => {
  //   try {
  //     const res = await mpCommunicationsService.create();
  
  //     dispatch({
  //       type: CREATE_MP_COMMUNICATIONS,
  //       payload: res.data,
  //     });
  
  //     return Promise.resolve(res.data);
  //   } catch (err) {
  //     return Promise.reject(err);
  //   }
  // }; 
  
  
  
  