import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10px;',
    width: '100%',
    maxWidth: 1024,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 450,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  li: {
    backgroundColor: 'inherit',
    '&:hover': {
        backgroundColor: 'secondary',
    }
  }


}));

export default function PinnedSubheaderList(props) {
  const classes = useStyles();

  const {list} = props;

  return (
    <List dense className={classes.root} subheader={<ul />}>
        <ListSubheader>
            <Typography variant={'h6'} color={'secondary'}>
                Перечень коммуникаций:
            </Typography>
        </ListSubheader>

        {list.map((item) => (
              <ListItem key={item}  >
                <ListItemText primary={`${item}`} />
              </ListItem>
        ))}
    </List>
  );
}


{/* <List dense className={classes.root} subheader={<li />}>
<ListSubheader>
    <Typography variant={'h6'} color={'secondary'}>
        Перечень коммуникаций:
    </Typography>
</ListSubheader>

{list.map((sectionId) => (
<li key={`section-${sectionId}`} className={classes.listSection}>
  <ul className={classes.ul}>
    
    {list.map((item) => (
      <ListItem key={`item-${sectionId}-${item}`}>
        <ListItemText primary={`${item}`} />
      </ListItem>
    ))}
  </ul>
</li>
))}
</List> */}