import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import {format} from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GetAppIcon from '@material-ui/icons/GetApp';

import DatePicker from '../../components/datePicker/datePicker';

import mlogsService from '../../services/mlogsService';

const useStyles = makeStyles((theme) => ({ 
    griditem: {
        width: 200,
    }
}));

export default function AdminLogsReport(props) { 
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [from, setFrom] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [to, setTo] = useState(format(new Date(), 'yyyy-MM-dd'));

    const exportExcel = () => {

        if(from > to) { alert("Неправильные даты"); return; }

        let filter = {
            date_from: from,
            date_to: to,
        }

        mlogsService.report(filter)
        .then((res) => {

            let reportStr = res.data;

            let wb = new ExcelJS.Workbook();
            let workbookName = "WO_LogsReport.xlsx";
            let worksheetName = `${from} - ${to}`;
            let ws = wb.addWorksheet(worksheetName);
        
            ws.columns = [
              {
                key: "user_id", 
                header: "ID", 
                width: 5 
              },
              {
                key: "user_name", 
                header: "ФИО", 
                width: 25 
              },
              {
                key: "tabs", 
                header: "Вкладки", 
                width: 10
              },
              {
                key: "actions", 
                header: "Действия", 
                width: 50
              },
              {
                key: "qty", 
                header: "Кол-во", 
                width: 10
              },        
            ];
        
            ws.getRow(1).alignment = { 
                vertical: 'top',
                wrapText: true
            };
        
            ws.getRow(1).font = {
              bold: true,
              color: { argb: "ffffff" },
            };
        
            ws.getRow(1).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00b050" }
            };
        
            for (let i = 0; i< reportStr.length; i++){
        
            ws.addRow({
                user_id: reportStr[i].ml_mu_id,
                user_name: reportStr[i].user_first_name + ' ' + reportStr[i].user_family_name,
                tabs: reportStr[i].mt_name,
                actions: reportStr[i].ma_name,
                qty: reportStr[i].QTY,
            });
        
            ws.getRow(i+2).alignment = { 
                vertical: 'top',
                horizontal: 'left',
                wrapText: true
            };
          }
        
          wb.xlsx.writeBuffer()
            .then(function(buffer){
              saveAs(
                  // new Blob([buffer], { type: "application/vnd.ms-excel" }),
                  new Blob([buffer], { type: "application/octet-stream" }),  
                  workbookName
              );
            });
          });
    }
  
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);        
    };

    // console.log('from: ', from);
    // console.log('to: ', to);
    // console.log('report: ', report);

    return (
        <div>
            <Grid container spacing={1} alignItems={'center'} > 
                <Grid item>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<GetAppIcon />}
                        onClick={handleClickOpen}
                        >
                        Отчет по работе
                    </Button>
                </Grid>

                <Grid item>
                    <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Период работы</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} display="flex" direction="row" >

                                <Grid item className={classes.griditem}>
                                    <DatePicker 
                                        label={"с"}
                                        value={from}
                                        setValue={setFrom}
                                    />
                                </Grid>

                                <Grid item className={classes.griditem}> 
                                    <DatePicker 
                                        label={"по"}
                                        value={to}
                                        setValue={setTo}
                                    />
                                </Grid>
                               
                            </Grid>
                        
                        </DialogContent>
                        
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Отмена
                            </Button>
                            <Button onClick={exportExcel} color="secondary">
                                Сформировать отчет
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            
          </Grid>
        </div>
    );
}        
