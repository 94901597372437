import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
 
import AdminLogsReport from './adminLogsReport';
import AdminPermissionsReport from './adminPermissionsReport';

// import usersService from '../../services/usersService';
// import tabsService from '../../services/mtabsService';
// import actionsService from '../../services/mactionsService';
import citiesService from '../../services/citiesService';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    table: {
        width: '1000px',
    },
  }));

export default function AdminReports(props) { 
    const classes = useStyles();

    // const [users, setUsers] = useState([]);    
    // const [tabs, setTabs] = useState([]);
    // const [actions, setActions] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
      // usersService.getAll()
      // .then((res) => {
      //   setUsers(res.data);
      // });

      // tabsService.getAll()
      // .then((res) => {
      //   setTabs(res.data);
      // });

      // actionsService.getAll()
      // .then((res) => {
      //   setActions(res.data);
      // });

      citiesService.getAll()
      .then((res) => {
        setCities(res.data);
      });

    }, []);

    // console.log('users: ', users);
    // console.log('tabs: ', tabs);
    // console.log('actions: ', actions);
    // console.log('cities: ', cities); 


  return (
    <div className={classes.root}>
        <Grid container spacing={2} direction="column" wrap="nowrap" alignItems="flex-start">

          <Grid item>
            <AdminPermissionsReport 
              cities={cities}
            />
          </Grid>

          <Grid item>
            <AdminLogsReport />
          </Grid>

        </Grid>
    </div>
  );
}