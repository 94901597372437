import {
    CREATE_CLIENTS_GO,
    RETRIEVE_CLIENTS_GO,
    RETRIEVE_CLIENTS_GO_BY_TYPE,
    GET_FILTERED_CLIENTS_GO,
    UPDATE_CLIENTS_GO,
    DELETE_CLIENTS_GO,
    SELECT_CLIENTS_GO,
    FILTER_CLIENTS_GO,
    SELECT_CHOSEN,
    GET_CLIENTS_GO_AND_BRANDS,
    ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS,
    SET_CLIENT_GO_FILTER,
  } from "./types";
  
  import clientsGoService from "../../services/clientsGoService";
  import store from '../store';
  
  export const createClientGo = (newClientGo) => async (dispatch) => {
    try {
      const res = await clientsGoService.create(newClientGo);
  
      dispatch({
        type: CREATE_CLIENTS_GO,
        payload: _transformClientGo(res.data),
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  function _transformClientGo(clientGo){
    return Object.assign({}, clientGo, {id: clientGo.cg_id}, {united: clientGo.cg_name.concat(' / ', clientGo.ctg_name) });    
  }

  export const selectChosen = (chosen) => {
    return {
      type: SELECT_CHOSEN,
      payload: chosen,
    }
  };
  
  
  export const retrieveClientsGo = () => async (dispatch) => {
    try {
      const res = await clientsGoService.getAll();
      const transformedClientsGo = res.data.map(item => _transformClientGo(item));
  
      dispatch({
        type: RETRIEVE_CLIENTS_GO,
        payload: transformedClientsGo.sort((a, b) => a.cg_name > b.cg_name ? 1 : -1),
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveClientsGoByType = (ctg_id) => async (dispatch) => {
    try {
      const res = await clientsGoService.getByType(ctg_id);
      const transformedClientsGo = res.data.map(item => _transformClientGo(item));
  
      dispatch({
        type: RETRIEVE_CLIENTS_GO_BY_TYPE,
        payload: transformedClientsGo.sort((a, b) => a.cg_name > b.cg_name ? 1 : -1)
      }); 
    } catch (err) {
      console.log(err);
    }
  };
  

  export const getFilteredClientsGo = (data) => async (dispatch) => {
    try {
      const res = await clientsGoService.filter(data);
  
      dispatch({
        type: GET_FILTERED_CLIENTS_GO,
        payload: res.data.map(item => _transformClientGo(item)),
      }); 
    } catch (err) {
      console.log(err);
    }
  };

  export const selectClientsGo = (array) => {
    return {
      type: SELECT_CLIENTS_GO, 
      payload: array,
    }
  };

  export const filterClientsGo = (str) => {
    return {
      type: FILTER_CLIENTS_GO, 
      payload: str,
    }
  };

  export const getClientsGoAndBrands = () => {
    const clientsGo = store.getState().clientsGo.clientsGo;
    const clientsGoArray = clientsGo.map(item => item.cg_name);
    console.log('clientsGoArray / cg reducer: ', clientsGoArray);
     
    const brands = store.getState().mprDetails.brandsFromMprDetails;
    console.log('brands / cg reducer: ', brands);

    const brandsToAdd = brands.filter(item => !clientsGoArray.includes(item));
    console.log('brandsToAdd / cg reducer: ', brandsToAdd);

    function makeClietGo(brand, index, startIndex){
        return {cg_id: index + startIndex, cg_name: brand, united: brand}
    }

    const startIndex = Math.max(...clientsGo.map(item => +item.cg_id)) + 1;
    console.log('startIndex / cg reducer: ', startIndex);

    const brandsToAddFn = (arr, startIndex, fn) => arr.map((item, index) => fn(item, index, startIndex));
    const brandsToAddArray = brandsToAddFn(brandsToAdd, startIndex, makeClietGo);
    console.log('brandsToAddArray / cg reducer: ', brandsToAddArray);

    const clientsGoAndBrands = clientsGo.concat(brandsToAddArray);
    console.log('clientsGoAndBrands / cg reducer: ', clientsGoAndBrands);

    return {
      type: GET_CLIENTS_GO_AND_BRANDS,
      payload: clientsGoAndBrands,
    }
  };

  export const addBrandToClientsGoAndBrands = (obj) => {
    return {
      type: ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS, 
      payload: obj,
    }
  };
  
  export const setClientGoFilter = (string) => {
    return {
      type: SET_CLIENT_GO_FILTER,
      payload: string,
    }
  };
  

  
  