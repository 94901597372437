import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { format, parseJSON } from 'date-fns';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import Spinner from '../../components/spinner/spinner';

import {pathToSmallPhoto} from '../../utils/shared';

export default function Reports1() {
    const {selectedIndex} = useSelector(state => state.shared);
    const {filteredMpDetails} = useSelector(state => state.mpDetails);

    const [base64, setBase64] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const excelExport = () => {

    const dataForReport = selectedIndex === 2
        ? filteredMpDetails.filter(item => item.mpc_name !== null)
        : filteredMpDetails;
    console.log('dataForReport:', dataForReport);

    // const urlArray = dataForReport.map(item => item.col10);

    // // photo loading for SHEET#2
    // useEffect(() => {
    //     // create base64 array form url array
    //     const createBlob = (response) => response.blob();

    //     const fromBlobToBase64 = (blob) =>
    //     new Promise((res) => {
    //         var reader = new FileReader();
    //         reader.onloadend = () => res(reader.result);
    //         reader.readAsDataURL(blob);
    //     });

    //     const createBase64ImageFromURL = (url) =>
    //     fetch(url)
    //         .then(createBlob)
    //         .then((blob) => fromBlobToBase64(blob));

    //     const createBase64ImagesFromURLArray = (arr) => {
    //         const array = [];
    //         const promiseArray = arr.reduce((promises, currentUrl) => {
    //             var promise = createBase64ImageFromURL(currentUrl);
    //             promise.then((base64) => array.push(base64));
    //             return promises;
    //         }, []);

    //         return Promise.all(promiseArray).then(() => array);
    //     };
            

    //     createBase64ImagesFromURLArray(urlArray)
    //         .then((data) => setBase64(data))
    //         .then(() => console.log('finished'))
    //         .then(() => setIsLoaded(true));
    // }, []);
        
    // console.log('base64: ', base64);       

    let wb = new ExcelJS.Workbook();
    let workbookName = "WO_Report#1.xlsx";
    let worksheetName = "Data";
    // let worksheetName2 = "List";
    let ws = wb.addWorksheet(worksheetName);
    // let ws2 = wb.addWorksheet(worksheetName2);


    ws.columns = [
      {
        key: "city", 
        header: "Город", 
        width: 25 
      },
      {
        key: "client", 
        header: "Рекламодатель", 
        width: 18 
      },
      {
        key: "type", 
        header: "Тип конструкции", 
        width: 18 
      },
      {
        key: "communication", 
        header: "Коммуникация", 
        width: 50,
      },
      {
        key: "year", 
        header: "Год", 
        width: 8,
        style: {
          alignment: { horizontal: "center" },
        } 
      },
      {
        key: "month", 
        header: "Месяц", 
        width: 8,
        style: {
          alignment: { horizontal: "center" },
        }  
      },
      {
        key: "week", 
        header: "Неделя", 
        width: 8,
        style: {
          alignment: { horizontal: "center" },
        } 
      },
      {
        key: "date", 
        header: "Дата мониторинга", 
        width: 14,
        style: {
          alignment: { horizontal: "center" },
        }
      },
      {
        key: "link", 
        header: "Фото", 
        width: 8,
        style: {
          alignment: { horizontal: "center" },
        } 
      },
    ];

    ws.getRow(1).alignment = { 
    vertical: 'top',
    horizontal: 'center',
    wrapText: true
    };

    ws.getRow(1).font = {
      bold: true,
      color: { argb: "ffffff" },
    };

    ws.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00b050" }
    };

    // ws2.columns = [
    // {
    //     key: "communication2", 
    //     header: "Коммуникация", 
    //     width: 50, 
    // },
    // {
    //     key: "client2", 
    //     header: "Рекламодатель", 
    //     width: 20 
    // },
    // {
    //     key: "photo", 
    //     header: "Образец фото", 
    //     width: 30 
    // },
    // ];

    // ws2.getRow(1).fill = {
    // type: "pattern",
    // pattern: "solid",
    // color: { argb: "ffffff" }, 
    // fgColor: { argb: "ff0000" }
    // };

    // ws2.getRow(1).font = { 
    // color: { argb: "ffffff" },
    // };

    // col1: obj.mprdp_photo_assigned_uf_id,
    // col2: obj.mpd_city,
    // col3: obj.mpd_address,
    // col4: obj.mpd_side,
    // col5: obj.mpd_side.substring(0,1),
    // col6: obj.mpd_size,
    // col7: obj.mpd_unit_type,
    // col8: obj.mpd_supplier,
    // col9: format(parseJSON(obj.mpr_planned_date), 'dd.MM.yyyy'),
    // col10: format(parseJSON(obj.mprdp_photo_assigned_date), 'dd.MM.yyyy'),
    // col11: format(parseJSON(obj.mprdp_photo_assigned_date), 'HH:mm:ss'),
    // col12: pathToSmallPhoto(obj),
    // col13: obj.cg_name || obj.mprd_brand,
    // col14: obj.ctg_name,
    // col15: 1,
    // col16: obj.mpc_name,

    for (let i = 0; i<dataForReport.length; i++){

      ws.addRow({
        city: dataForReport[i].mpd_city,
        client: dataForReport[i].cg_name || dataForReport[i].mprd_brand,
        type: dataForReport[i].mpd_unit_type,
        communication: dataForReport[i].mpc_name,
        year: +format(parseJSON(dataForReport[i].mpr_planned_date), 'yyyy'), 
        month: +format(parseJSON(dataForReport[i].mpr_planned_date), 'MM'),
        week: +format(parseJSON(dataForReport[i].mpr_planned_date), 'ww'),
        date: format(parseJSON(dataForReport[i].mpr_planned_date), 'dd.MM.yyyy'),
        link: pathToSmallPhoto(dataForReport[i]),
      });

        // ws.getCell(`K${i+2}`).value = {
        //     text: "фото (1 шт.)",
        //     hyperlink: pathToSmallPhoto(dataForReport[i]),
        // };
    
        ws.getCell(`D${i+2}`).font = {
            color: { argb: "0000FF" },
            // underline: true
        };

        // ws.getCell(`B${i+2}`).font = { 
        //     italic: true,
        // };

        // variant with the hyperlink
        // ws.getCell(`D${i+2}`).value = {
        //     text: dataForReport[i].col5 ? dataForReport[i].col5 : 'No communication',
        //     year: dataForReport[i].col6,
        //     hyperlink: "#\'List\'!A" + (i+2),
        // };

        // ws.getCell(`D${i+2}`).font = {
        //     color: { argb: "0000FF" },
        //     underline: true
        // };

                // variant without the hyperlink
        // ws.getCell(`D${i+2}`).value = dataForReport[i].col5;

        // ws.getCell(`D${i+2}`).font = {
        //     color: { argb: "0000FF" },
        // };

        ws.getCell(`I${i+2}`).value = {
            text: "ссылка",
            hyperlink: pathToSmallPhoto(dataForReport[i]),
        };
    
        ws.getCell(`I${i+2}`).font = {
            color: { argb: "0000FF" },
            underline: true
        };

        // SHEET#2
        // ws2.getRow(i+2).height = 200;
        // ws2.getRow(i+2).alignment = { 
        //     vertical: 'top',
        //     wrapText: true
        // };

        // ws2.getCell(`A${i+2}`).value = {
        //     text: dataForReport[i].col5 ? dataForReport[i].col5 : 'No communication',
        //     hyperlink: dataForReport[i].col10,
        // };  
        // ws2.getCell(`A${i+2}`).font = {
        //     color: { argb: "0000FF" },
        //     underline: true
        // };

        // ws2.getCell(`B${i+2}`).value = dataForReport[i].col3;
    }
    
    // const excelExport = () => { 
      // add images from images array
        // for (let i = 0; i < dataForReport.length; i++){
        //     const imageId2 = wb.addImage({
        //         base64: base64[i],
        //         extension: 'jpeg',
        //     });

        //     ws2.addImage(imageId2, `C${i+2}:D${i+2}`);
        // }

        wb.xlsx.writeBuffer()
            .then(function(buffer){
                saveAs(
                    // new Blob([buffer], { type: "application/vnd.ms-excel" }),
                    new Blob([buffer], { type: "application/octet-stream" }),  
                    workbookName
                );
            });
    }
 
  return (
    <Button
    variant="contained"
    color="default"
    startIcon={<GetAppIcon />}
    onClick={excelExport}
  >
    Отчет 1
  </Button>
  )
}