import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { lightBlue } from '@material-ui/core/colors';

import { SELECT_CLIENTS_GO } from '../../redux/actions/types';
import { getFilteredMprDetails, getBrands } from '../../redux/actions/mprDetails';
import { retrieveClientsGo, selectClientsGo, createClientGo } from '../../redux/actions/clientsGo';
import {saveLog} from '../../redux/actions/mlogs';

import {_baseApiUrl} from '../../redux/constants';


const useStyles = makeStyles((theme) => ({    
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minWidth: '400px;',
        width: '100%',
    },
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
        // marginBottom: '10px;',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
  }));

export default function DialogAddClientGo(props) {
  const classes = useStyles();
  const {selected} = props;

  const {unrelated, filteredMprDetails, brands, selectedBrands} = useSelector(state => state.mprDetails);
  const {chosen, clientsGo, selectedClientsGo } = useSelector(state => state.clientsGo);
  const {filter} = useSelector(state => state.shared);
  const {user_id, user_role, user_permissions} = useSelector(state => state.users)

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [cg_id, setNewClientId] = useState(null);
  const [cg_name, setNewClientName] = useState('');  
  const [ctg_id, setNewClientTypeGoId] = useState('16');

  let isClientExist = false;

  const handleClientGo = (event) => {
    setNewClientName(event.target.value || '');
  };

  const handleClientTypeGo = (event) => {
    setNewClientTypeGoId(+event.target.value || 0);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // console.log('selected inside dialog: ', selected);
    // if(selected.length > 0) setNewClientName(selected[0]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const customTrim = (str) => {
    if(!str) { str = 'Бренд не проставлен'; } else { str = str.trim(); } 
    return str;
  };

  const saveNewClient = () => {

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'brands-CREATE_THE_CLIENT_GO')) {
      alert('В доступе отказано. Обратитесь к администратору');
      return;   
    }
    
    setOpen(false);
    console.log('cg_id: ', cg_id );
    console.log('cg_name: ',cg_name );
    console.log('selected: ', selected );
    // console.log('cg_name: ', cg_name, ' ', typeof cg_name);
    console.log('ctg_id: ', ctg_id, ' ', typeof ctg_id);

    const newClientGo = cg_name 
      ? {
          cg_name: cg_name,
          cg_ctg_id: +ctg_id,
        }
      : {
          cg_name: selected,
          cg_ctg_id: +ctg_id,
        };

    console.log('newClientGo: ', newClientGo);

    if(clientsGo.find(item => item.cg_name.toLowerCase() === newClientGo.cg_name.toLowerCase() & item.ctg_id === +newClientGo.cg_ctg_id)) {
      alert('Такой Client_Go / Client_Type_Go уже существует');
      isClientExist = true;
    }
    else {

      const mLog = {
        ml_mu_id: user_id,
        ml_mt_id: 2,
        ml_ma_id: 9, 
        ml_qty: 1,
        ml_comments: JSON.stringify(newClientGo),
      }
        
      dispatch(createClientGo(newClientGo))
        .then(data => {
          console.log('ответ пришел:', data);
          dispatch({
            type: SELECT_CLIENTS_GO,
            payload: [].concat(data.cg_id),              
          });
        })
        .then(() => {
          dispatch(saveLog(mLog))
            .then(data => console.log('СОЗДАТЬ КЛИЕНТА - ЛОГ СОХРАНЕН:', data))
            .catch(e => {
              console.log(e);
            });
        })
        .catch(e => {
          console.log(e);
        });
    }    
  }

  const saveNewClientAndBindBrands = () => {

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'brands-BIND_BRANDS_TO_THE_CLIENTS_GO')) {
      alert('В доступе отказано. Обратитесь к администратору');
      return;   
    }
    
    // функция для ПРИВЯЗКИ МАССИВА брендов из brands.jsx

    const onBindBrandsToTheClientGo = (cg_id) => {
      console.log('brandsToClients: ', selectedBrands);
      console.log('toClientGo id: ', cg_id);
      console.log('filteredMprDetails set_client RIGHT: ', filteredMprDetails);

      const brandsList = selectedBrands.map(item => brands[item].united);
      console.log('brandsList: ', brandsList);

      const mprDetail = filteredMprDetails.filter(item => brandsList.includes(customTrim(item.mprd_brand)));
        console.log('mprDetail через brandList: ', mprDetail);

      const newMprDetails = mprDetail.map(item => ({
          mprd_id: item.mprd_id,
          mprd_client_go: cg_id
      }));
      console.log('newMprDetails: ', newMprDetails);

      const url = `${_baseApiUrl}/mpr_details/set_client`;
      // console.log('Url: ', url);

      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json; charset=utf-8'},
        body: JSON.stringify(newMprDetails)
      };

      const mLog1 = {
        ml_mu_id: user_id,
        ml_mt_id: 2,
        ml_ma_id: 3, 
        ml_qty: newMprDetails.length,
        ml_comments: JSON.stringify(newMprDetails),
      }


      fetch(url, requestOptions)
      .then(res => res.json())
      .then(
        (data) => {
          console.log('data: ', data);
          console.log('ЗАПРОС ПРИВЯЗАТЬ ОТРАБОТАЛ')
          dispatch(getBrands(filter, unrelated));
          dispatch(getFilteredMprDetails(filter, unrelated));  
      })
      .then(() => {
        dispatch(saveLog(mLog1))
          .then(data => console.log('ЛОГ СОХРАНЕН:', data))
          .catch(e => {
            console.log(e);
          });
      })
      .catch(e => {
        console.log(e);
      });
    }

    //создаем и привязываем

    setOpen(false);
    console.log('cg_name: ', cg_name, ' ', typeof cg_name);
    console.log('ctg_id: ', ctg_id, ' ', typeof ctg_id);

    const newClientGo = cg_name 
      ? {
          cg_name: cg_name,
          cg_ctg_id: +ctg_id,
        }
      : {
          cg_name: selected,
          cg_ctg_id: +ctg_id,
        };

    console.log('newClientGo: ', newClientGo);

    if(clientsGo.find(item => item.cg_name.toLowerCase() === newClientGo.cg_name.toLowerCase() && item.ctg_id === +newClientGo.cg_ctg_id)) {
      alert('Такой Client_Go / Client_Type_Go уже существует');      
      isClientExist = true;
      return;
    }
    else {

      const mLog2 = {
        ml_mu_id: user_id,
        ml_mt_id: 2,
        ml_ma_id: 9, 
        ml_qty: 1,
        ml_comments: JSON.stringify(newClientGo),
      };
      
      dispatch(createClientGo(newClientGo))
          .then(data => {
              console.log('ОТВЕТ ПРИШЕЛ:', data);
              console.log('ЗАПУСКАЕМ ПРИВЯЗКУ к ', data.cg_id);              
              onBindBrandsToTheClientGo(data.cg_id);
          })
          .then(() => {
            dispatch(saveLog(mLog2))
              .then(data => console.log('ЛОГ#2 СОХРАНЕН:', data))
          })
          .catch(e => console.log(e));
    }
  }

  return (
    <div>
        <Button
            variant="contained"
            size="large" 
            className={classes.additional}
            onClick={handleClickOpen}
        >
            Добавить
        </Button>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Client_Go</DialogTitle>
        
        <DialogContent>
            <DialogContentText>
                Чтобы добавить нового Client_Go в справочник введите его название и укажите его категорию, выбрав из списка ниже.
            </DialogContentText>
            <form className={classes.container}>
                <InputLabel htmlFor="demo-dialog-native"></InputLabel>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название нового Client_Go"
                        type="text"
                        onChange={handleClientGo}
                        fullWidth
                        color="secondary"
                        defaultValue={selected}
                    />

                <InputLabel id="demo-dialog-select-label"></InputLabel>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    color="secondary"
                    value={ctg_id}
                    onChange={handleClientTypeGo}
                    input={<Input />}
                >
                    <MenuItem value={1}>АВТОМОБИЛИ, СЕРВИС</MenuItem>
                    <MenuItem value={2}>АЛКОГОЛЬНЫЕ НАПИТКИ</MenuItem>
                    <MenuItem value={3}>АУДИО- И ВИДЕОТЕХНИКА, ФОТОТОВАРЫ</MenuItem>
                    <MenuItem value={4}>БЕЗАЛКОГОЛЬНЫЕ НАПИТКИ</MenuItem>
                    <MenuItem value={5}>БЫТОВАЯ ТЕХНИКА</MenuItem>
                    <MenuItem value={6}>БЫТОВАЯ ХИМИЯ</MenuItem>
                    <MenuItem value={7}>ИНТЕРНЕТ-РЕСУРСЫ И УСЛУГИ</MenuItem>
                    <MenuItem value={8}>КОМПЬЮТЕРЫ, ОРГТЕХНИКА, КАНЦЕЛЯРСКИЕ ТОВ</MenuItem>
                    <MenuItem value={9}>КОНДИТЕРСКИЕ ИЗДЕЛИЯ</MenuItem>
                    <MenuItem value={10}>КОРМ ДЛЯ ЖИВОТНЫХ</MenuItem>
                    <MenuItem value={11}>КОРПОРАТИВНАЯ РЕКЛАМА</MenuItem>
                    <MenuItem value={12}>КОСМЕТИКА, ПАРФЮМЕРИЯ, СРЕДСТВА ГИГИЕНЫ</MenuItem>
                    <MenuItem value={13}>МЕБЕЛЬ</MenuItem>
                    <MenuItem value={14}>МЕДИЦИНА</MenuItem>
                    <MenuItem value={15}>НЕДВИЖИМОСТЬ И СТРОИТЕЛЬСТВО</MenuItem>
                    <MenuItem value={16}>НЕТ ДАННЫХ</MenuItem>
                    <MenuItem value={17}>ОДЕЖДА И ОБУВЬ</MenuItem>
                    <MenuItem value={18}>ОПТОВО-РОЗНИЧНАЯ ТОРГОВЛЯ</MenuItem>
                    <MenuItem value={19}>ПОЛИТИЧЕСКАЯ РЕКЛАМА</MenuItem>
                    <MenuItem value={20}>ПРОДУКТЫ ПИТАНИЯ</MenuItem>
                    <MenuItem value={21}>ПРОЧИЕ ТОВАРЫ И УСЛУГИ</MenuItem>
                    <MenuItem value={22}>СМИ И ПОЛИГРАФИЯ</MenuItem>
                    <MenuItem value={23}>СПОРТИВНЫЕ ТОВАРЫ, УСЛУГИ</MenuItem>
                    <MenuItem value={24}>ТОВАРЫ ДЛЯ ДОМА</MenuItem>
                    <MenuItem value={25}>ТУРИЗМ, РАЗВЛЕЧЕНИЯ</MenuItem>
                    <MenuItem value={26}>УСЛУГИ СВЯЗИ, СРЕДСТВА СВЯЗИ</MenuItem>
                    <MenuItem value={27}>ФИНАНСОВЫЕ УСЛУГИ, БАНКИ</MenuItem>
                    <MenuItem value={28}>ЮВЕЛИРНЫЕ ИЗДЕЛИЯ, ГАЛАНТЕРЕЯ</MenuItem>
                    <MenuItem value={29}>ГОРОДСКАЯ И СОЦИАЛЬНАЯ РЕКЛАМА</MenuItem>
                    <MenuItem value={30}>РЕКЛАМНЫЕ УСЛУГИ</MenuItem>
                    <MenuItem value={31}>ПУСТОЙ</MenuItem>
                </Select>
            </form>
        </DialogContent>
          
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={saveNewClient} color="secondary">
            Сохранить
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={saveNewClientAndBindBrands} 
            className={classes.success}
          >
            Сохранить и привязать
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
