import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MaketsMenu from '../maketsMenu/maketsMenu';
import Reports1 from '../maketsReport/reports1';
import Reports2 from '../maketsReport/reports2';

import { showDuplicates, showList } from '../../redux/actions/shared';

import {_baseApiUrl} from '../../redux/constants';

const useStyles = makeStyles((theme) => ({ 
    export: {
      color: 'black',
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    dialogcontainer: {
      marginBottom: '10px',
    },
    info: {
        padding: '14px;'
    },
  }));

export default function MaketsActions() {
  const classes = useStyles();

  const {user_role, user_permissions} = useSelector(state => state.users);
  const {filteredMpDetails} = useSelector(state => state.mpDetails);
  const { duplicatesFlag, listFlag} = useSelector(state => state.shared);  

  const dispatch = useDispatch();

  const [openExportTable, setOpenExportTable] = useState(false);
  
  const handleDuplicates = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showDuplicates(e.target.checked ? 'Yes' : 'No'));
  }

  const handleList = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showList(e.target.checked ? 'Yes' : 'No'));
  }

  const handleExport = (e) => {   
    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'EXPORT_TO_EXCEL')) {
      alert('В доступе отказано. Обратитесь к администратору'); 
      return;   
    } 
    setOpenExportTable(!openExportTable);
    console.log('openExportTable: ', openExportTable);
  }

  return (
    <div>
        {filteredMpDetails.length>0 &&
        <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>
           
            <Grid item>    
                <MaketsMenu
                    options = {['Все', 'Выбранные', 'Невыбранные']}
                />
            </Grid>
           
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={duplicatesFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleDuplicates} 
                            />}
                    label="Дубли"
                    labelPlacement="end"
                />
            </Grid>

            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={listFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleList} 
                            />}
                    label="Перечень коммуникаций"
                    labelPlacement="end"
                />
            </Grid>
            
            <Grid item>
                <Button
                    variant="outlined"
                    size="large"
                    color="default" 
                    className={classes.export}
                    onClick={handleExport}
                >
                    Отчеты
                </Button>
            </Grid>

            {openExportTable && 
            <Grid item>
                <Reports1 />
            </Grid>}

            {openExportTable && 
            <Grid item>
                <Reports2 />
            </Grid>}

            <Grid item>
                <Button 
                    variant="outlined" 
                    color="default"
                    size="large"
                    // className={classes.info}
                > 
                    {`Всего фото: ${filteredMpDetails.length}`}
                </Button>
          </Grid>

        </Grid>}
      
    </div>
  );
}