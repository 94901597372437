import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS,
    // GET_MP_COMMUNICATION_FROM_MPR_DETAIL,
    UPDATE_ONE_MPR_DETAIL,
    UPDATE_MANY_FILTERED_MPR_DETAILS,   
    SELECT_UNRELATED,
    GET_BRANDS_FROM_MPR_DETAILS,
    GET_BRANDS,
    GET_BRANDS_RELATED,
    SELECT_BRANDS,
    BIND_PHOTO_TO_THE_CLIENTS_GO,
    BIND_BRANDS_TO_THE_CLIENTS_GO,
    GET_MONTHS_RELATED_TO_THE_CLIENT,
    SELECT_MPR_DETAILS,
  } from "../actions/types"; 
  
  const initialState = {
    unrelated: true,
    mprDetails: [],
    filteredMprDetails: [],
    brandsFromMprDetails: [],
    brands: [],
    selectedBrands: [],
    months: [],
    updatedMprDetail: {},
    selectedMprDetails: [], 
    // mpCommunicationFromMprDetail: '',
  };
  
  function mprDetailsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_MPR_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                mprDetails: payload
        }
      
      case GET_FILTERED_MPR_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredMprDetails: payload
        }
      
      // case GET_MP_COMMUNICATION_FROM_MPR_DETAIL:
      //   if(!payload) return state;
      //   return {
      //           ...state,
      //           mpCommunicationFromMprDetail: payload
      //   }

      case UPDATE_ONE_MPR_DETAIL:
        if(!payload) return state;

        const index = state.mprDetails.findIndex(item => item.mprd_id === payload.mprd_id);
        const newArray = [...state.mprDetails]; //making a new array
        for (const property in newArray[index]) {
          newArray[index][property] = payload[property];
        }

        return { 
            ...state,              //copying the orignal state
            mprDetails: newArray,  //reassingning to new array
        }

      case UPDATE_MANY_FILTERED_MPR_DETAILS: // only mprd_client_go prop!
        if(!payload) return state;

        //const index = state.mprDetails.findIndex(item => item.mprd_id === payload.mprd_id);
        const newFilteredMprDetailsArray = [...state.mprDetails]; //making a new array
        for (let i = 0; i<payload.length; i++) {
          const index = newFilteredMprDetailsArray.findIndex(el => el.mprd_id === payload[i].mprd_id);
          newFilteredMprDetailsArray[index].mprd_client_go = payload[i].mprd_client_go;
        }

        return { 
            ...state,              //copying the orignal state
            mprDetails: newFilteredMprDetailsArray,  //reassingning to new array
        }
      
        
        case GET_BRANDS_FROM_MPR_DETAILS:
          if(!payload) return state;
          return {
                  ...state,
                  brandsFromMprDetails: payload
          }

        case GET_BRANDS:
        if(!payload) return state;
        return {
                ...state,
                brands: payload
        }

      case GET_BRANDS_RELATED:
        if(!payload) return state;
        return {
                ...state,
                brands: payload
        }
      
      case GET_MONTHS_RELATED_TO_THE_CLIENT:
        if(!payload) return state;
        return {
                ...state,
                months: payload.sort()
        }
      
      case SELECT_BRANDS:
        if(!payload) {
          return {
            ...state,
            selectedBrands: [],
          }
        }
        else {
          return {
            ...state,
            selectedBrands: payload,
          }        
        }

      case SELECT_UNRELATED:
        return {
                ...state,
                unrelated: payload,
      }  

      case BIND_PHOTO_TO_THE_CLIENTS_GO:
        if(!payload) return state;
        return {
                ...state,
                updatedMprDetail: Object.assign({}, payload),
        }     
  
      case BIND_BRANDS_TO_THE_CLIENTS_GO:
        return {
          ...state,
          selectedBrands: []
        }

      case SELECT_MPR_DETAILS:
        return {
          ...state,
          selectedMprDetails: payload,
        } 

        
    //   case DELETE_CLIENTS_GO:
    //     return clientsGo.filter(({ id }) => id !== payload.id);
  
      default:
        return state;
    }
  };
  
  export default mprDetailsReducer;

          // case SELECT_BRANDS:
        //   if(payload.selectionModel.length === 0) {
        //     return {
        //       ...state,
        //       selectedBrands: [],
        //     }
        //   }
        //   else {
        //     return {
        //       ...state,
        //       selectedBrands: payload.selectionModel,
        //     }        
        //   }  