import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(`/mlogs`, { headers: authHeader() });
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mlogs/${id}`, { headers: authHeader() })
}

const create = (data) => {
    return axios.post(_baseApiUrl + `/mlogs`, data, { headers: authHeader() });
}

const report = (filter) => {
    return axios.post(_baseApiUrl + `/mlogs/report`, filter, { headers: authHeader() });
};

const mlogsService = {
    getAll,
    get,
    create,
    report,
};
  
export default mlogsService;