import {
    RETRIEVE_MONITORING_PROJECTS,
    GET_FILTERED_MONITORING_PROJECTS,
  } from "./types";
  
import monitoringProjectsService from "../../services/monitoringProjectsService"; 


export const retrieveMonitoringProjects = () => async (dispatch) => {
  try {
    const res = await monitoringProjectsService.getAll();
    const monitoringProjects = res.data.map(item => item.mp_id);

    dispatch({
      type: RETRIEVE_MONITORING_PROJECTS,
      payload: monitoringProjects.sort(function(a, b) {return b - a;}),
    });
  } catch (err) {
    console.log(err);
  }
}; 

export const getFilteredMonitoringProjects = (data) => async (dispatch) => {
  try {
    const res = await monitoringProjectsService.filter(data);

    dispatch({
      type: GET_FILTERED_MONITORING_PROJECTS,
      payload: [...new Set(res.data.map(item => item.mpr_mp_id))],
    });
  } catch (err) {
    console.log(err);
  }
};
 