import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    SET_THE_FILTER,
    SET_THE_MODE,
    SHOW_SELECTED,
    SHOW_UNSELECTED,
    SHOW_DUPLICATES,
    SHOW_LIST,
    SHOW_AUTO,
    SHOW_SIDE,
    SHOW_TYPE,
    SHOW_GEO,
    SEARCH_STRING,
  } from "./types";

import store from '../store';
  
export const resetTheStore = () => {
    return {
      type: RESET_THE_STORE_TO_INITIAL_STATE, 
    }
};

export const setTheFilter = (obj) => {
  return {
    type: SET_THE_FILTER,
    payload: obj, 
  }
};

export const setTheMode = (str) => {
  return {
    type: SET_THE_MODE,
    payload: str, 
  }
};

export const showSelected = (index) => { // 1,2,3
  return {
    type: SHOW_SELECTED,
    payload: index,
  }
};


export const showDuplicates = (flag) => { // 'Yes' or 'No'
  return {
    type: SHOW_DUPLICATES,
    payload: flag
  }
};

export const showList = (flag) => { // 'Yes' or 'No'
  return {
    type: SHOW_LIST,
    payload: flag
  }
};

export const showAuto = (flag) => { // 'Yes' or 'No'
  return {
    type: SHOW_AUTO,
    payload: flag,
  }
};

export const showSide = (flag) => { // 'Yes' or 'No'
  return {
    type: SHOW_SIDE,
    payload: flag,
  }
};

export const showType = (flag) => { // 'Yes' or 'No'
  return {
    type: SHOW_TYPE,
    payload: flag,
  }
};

export const showGeo = (index) => { // 1,2,3,4,5
  return {
    type: SHOW_GEO,
    payload: index,
  }
};


export const searchString = (string) => { 
  return {
    type: SEARCH_STRING,
    payload: string,
  }
};