import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MonitoringsMenu from '../monitoringsMenus/monitoringsMenu';
import GeoMenu from '../monitoringsMenus/geoMenu';

import { selectMonitoringsAutoTableAddresses } from '../../redux/actions/mpDetails';
import { selectClientsGo, setClientGoFilter } from '../../redux/actions/clientsGo';
import { showAuto, showSide, showType} from '../../redux/actions/shared';
import { saveLog } from '../../redux/actions/mlogs';

import mprDetailsService from '../../services/mprDetailsService';
import filesService from "../../services/filesService";
import mprDetailsPhotosService from "../../services/mprDetailsPhotosService";

import { to7digitals } from '../../utils/shared';
import {_baseApiUrl} from '../../redux/constants';
import { selectMprDetails } from '../../redux/actions/mprDetails';
import {updateFilteredMpDetailsSimple} from '../../redux/actions/mpDetails';

const useStyles = makeStyles((theme) => ({ 
    export: {
      color: 'black',
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    dialogcontainer: {
      marginBottom: '10px',
    },
    info: {
        padding: '14px;'
    },
    success: {
      flexBasis: '75%',
      padding: '14px;',
      marginRight: '0px;',
      color: '#fff',
    },
    cancel: {
      flexBasis: '25%',
      padding: '14px;',
      color: '#fff',
    },
  }));

export default function MonitoringsActions() {
  const classes = useStyles();

  const {user_id, user_role, user_permissions} = useSelector(state => state.users);
  const {monitoringsAutoTableAddresses} = useSelector(state => state.mpDetails);
  const {filteredMprDetails, selectedMprDetails} = useSelector(state => state.mprDetails);
  const {selectedClientsGo} = useSelector(state => state.clientsGo);
  const {mode, autoFlag, sideFlag, typeFlag, selectedIndex} = useSelector(state => state.shared);  

  const dispatch = useDispatch();

  
  const handleAuto = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showAuto(e.target.checked ? 'Yes' : 'No'));
  }

  const handleSide = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showSide(e.target.checked ? 'Yes' : 'No'));
  }

  const handleType = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showType(e.target.checked ? 'Yes' : 'No'));
  }

  const bindOne = async (selectedClient, selectedMprDetail) => { // 1 client + 1 mprDetail
    const fileName = to7digitals(selectedMprDetail.mprdp_photo_assigned_uf_id) + "_" + to7digitals(selectedMprDetail.uf_rand) + "_900." + selectedMprDetail.uf_extension;

    const sourceProjectDirectory = to7digitals(selectedMprDetail.mprd_mpr_id);
    const targetProjectDirectory = to7digitals(selectedClient.mpr_id_simple);
    
    const res = await mprDetailsService.getByMpdId(selectedClient.id); 
    const mprDetailsIdSimple = res.data[0].mprd_id;
    console.log('mprDetailsIdSimple / M ACTIONS: ', mprDetailsIdSimple);

    const res2 = await mprDetailsPhotosService.get(selectedMprDetail.mprdp_id);
    const mprdPhotos = res2.data;
    delete mprdPhotos.mprdp_id;
  
    const obj = {
      file_name: fileName, 
      source_dir: sourceProjectDirectory,
      target_dir: targetProjectDirectory,
    }
    
    const newMprdPhotos = Object.assign({}, mprdPhotos, {mprdp_mprd_id: mprDetailsIdSimple, mprdp_deleted: 0});
    
    console.log('obj / M ACTIONS: ', obj);
    console.log('newMprdPhotos / M ACTIONS: ', newMprdPhotos);

    const res3 = await filesService.copyFile(obj);
    console.log('результат копирования фото: / M ACTIONS', res3.data);

    const res4 = await mprDetailsPhotosService.create(newMprdPhotos);
    console.log('результат добавления записи в mprdp: / M ACTIONS', res4.data);

    const mLog = {
      ml_mu_id: user_id,
      ml_mt_id: 5,
      ml_ma_id: 12 , 
      ml_qty: 1,
      ml_comments: JSON.stringify({
        fileName: fileName,
        source_dir: sourceProjectDirectory,
        target_dir: targetProjectDirectory,
        newMprdPhotos: res4.data.mprdp_id,
      }),
    }

    dispatch(saveLog(mLog));
  }

  const bindMany = async (selectedClient, selectedMprDetails) => {
    const newPhotosArray = [];
    for (let i = 0; i<selectedMprDetails.length;i++) {
      await bindOne(selectedClient, selectedMprDetails[i]);  
      newPhotosArray.push("https://monitoring.graceoutdoor.ru/" + to7digitals(selectedClient.mpr_id_simple) + "/" + to7digitals(selectedMprDetails[i].mprdp_photo_assigned_uf_id) + "_" + to7digitals(selectedMprDetails[i].uf_rand) + "_900." + selectedMprDetails[i].uf_extension)
    }
    return newPhotosArray
  }

  const handleBind = async (e) => {

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'monitorings-BIND_MASS_MONITORING_PHOTO')) {
      alert('В доступе отказано. Обратитесь к администратору'); 
      return;   
    }

    console.log('====== ПРИВЯЗКА ФОТО МАСС МОНИТОРИНГА К ОБЫЧНОМУ ОТЧЕТУ ======');

    if(autoFlag === 'No') {
      console.log('------ ДВЕ ТАБЛИЦЫ ------');

      if(selectedMprDetails.length === 0 || selectedClientsGo.length === 0) {alert('Выберите записи в обеих таблицах'); return;}
        
      const newPhotos = await bindMany(selectedClientsGo[0], selectedMprDetails);
      console.log('newPhotos to add to fileteredMpDetailsSimle: ', newPhotos);
      dispatch(updateFilteredMpDetailsSimple(selectedClientsGo[0].id, newPhotos));
      dispatch(selectClientsGo([]));
      dispatch(selectMprDetails([]));
    }
    else {
      console.log('------ ОДНА АВТО ТАБЛИЦА ------');
      if(monitoringsAutoTableAddresses.length === 0) {alert('Выберите хотя бы одну запись'); return;}
      if(monitoringsAutoTableAddresses.filter(item => item.address === 'НЕТ ВАРИАНТОВ').length > 0) {alert('Снимите галочку для строк НЕТ ВАРИАНТОВ'); return;}

      for (let i = 0; i<monitoringsAutoTableAddresses.length;i++) {
        await bindOne(monitoringsAutoTableAddresses[i], monitoringsAutoTableAddresses[i]);
        const newPhotosAuto = [].concat("https://monitoring.graceoutdoor.ru/" + to7digitals(monitoringsAutoTableAddresses[i].mpr_id_simple) + "/" + to7digitals(monitoringsAutoTableAddresses[i].mprdp_photo_assigned_uf_id) + "_" + to7digitals(monitoringsAutoTableAddresses[i].uf_rand) + "_900." + monitoringsAutoTableAddresses[i].uf_extension);
        dispatch(updateFilteredMpDetailsSimple(monitoringsAutoTableAddresses[i].id, newPhotosAuto));
        dispatch(selectMonitoringsAutoTableAddresses([]));
      }
    }    
  }


  const handleUnbind = (e) => {
    console.log('e.target', e.target);

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'monitorings-BIND_MASS_MONITORING_PHOTO')) {
      alert('В доступе отказано. Обратитесь к администратору'); 
      return;   
    }

    // dispatch(showType(e.target.checked ? 'Yes' : 'No'));
  }

  console.log('selectedClientsGo / M ACTIONS: ', selectedClientsGo);
  console.log('selectedMprDetails / M ACTIONS: ', selectedMprDetails);
  console.log('monitoringsAutoTableAddresses: ', monitoringsAutoTableAddresses); 

  return (
    <div>
        {filteredMprDetails.length>0 &&
        <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>             
           
          {mode === 'Monitorings1' &&  
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={autoFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleAuto} 
                            />}
                    label="Авто"
                    labelPlacement="end"
                />
            </Grid>
            }

            <Grid item>    
                <MonitoringsMenu
                  options = {['Непривязанные', 'Привязанные', 'Все']}
                />
            </Grid>

            {autoFlag === 'No' &&
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={sideFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleSide} 
                            />}
                    label="Ст."
                    labelPlacement="end"
                />
            </Grid>
            }

            {autoFlag === 'No' &&
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={typeFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleType} 
                            />}
                    label="Тип"
                    labelPlacement="end"
                />
            </Grid>
            }

            {autoFlag === 'No' &&
            <Grid item>    
                <GeoMenu/>
            </Grid>
            }


            {(selectedClientsGo.length > 0 || autoFlag === 'Yes') && // selectedIndex !== 2 && 
            <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    color="secondary" 
                    className={classes.success}
                    onClick={handleBind}
                >
                    Привязать
                </Button>
            </Grid>}

            {selectedClientsGo.length > 0 && autoFlag === 'No' && selectedIndex === 2 &&
            <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    color="primary" 
                    className={classes.cancel}
                    onClick={handleUnbind}
                >
                    Отвязать
                </Button>
            </Grid>}

        </Grid>}
      
    </div>
  );
}