import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';

import { createMpCommunication, retrieveMpCommunications } from '../../redux/actions/mpCommunications';
import { updateMprDetail } from '../../redux/actions/mprDetails';
import { saveLog } from '../../redux/actions/mlogs';

import { getFilteredMpDetails, updateFilteredMpDetail } from '../../redux/actions/mpDetails';
import mprDetailsService from '../../services/mprDetailsService';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: theme.palette.primary.light,
    marginLeft: '0px;',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      
  },
  icon: {
    visibility: 'visible',
    backgroundColor: 'white',
    // backgroundColor: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    color: 'action',
  },
  chosen: {
    visibility: 'visible',
    backgroundColor: 'white',    
  },
  checkbox: {
    visibility: 'visible',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    color: "secondary",
    borderRadius: '0',
    padding: '2px !important;',
  },
  tooltip: {
    fontSize: '1rem',
    maxWidth: '600px;',
    backgroundColor: 'white',
    color: 'black',
  },
  communication: {
    marginBottom: '14px;'
  },
  dialogactions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px;',
  },

}));

const filterOptions = createFilterOptions({
  limit: 25,
});

export default function MaketsImageDialog(props) {

    const classes = useStyles(); 

    const dispatch = useDispatch();
    const {mpCommunications} = useSelector(state => state.mpCommunications);
    const {filter} = useSelector(state => state.shared);

    const {user_id, user_role, user_permissions} = useSelector(state => state.users);
    // console.log('mpCommunications: ', mpCommunications);

    const {item, setItem} = props;
    const [checked, setChecked] = useState(item.mprd_communication_id === 0 ? false : true);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    
    const [open, setOpen] = useState(false); 

    // useEffect(() => {
      
    //   async function getMprDetail(mprd_id){
    //     console.log('ЗАПРОС mprDetail ПОШЕЛ,: ', mprd_id);

    //     const {data} = await mprDetailsService.get(mprd_id);
    //     console.log('mprDetail: ', data);

    //     const isCommExist = data.mprd_communication_id !== 0 ? true : false;
    //     console.log('isCommExist: ', isCommExist);
        
    //     if(isCommExist) {
    //       setChecked(true);
    //       let i = mpCommunications.findIndex(comm => comm.mpc_id === data.mprd_communication_id);
    //       // console.log('i: ', i);
    //       setValue(mpCommunications[i]);
    //       setInputValue(mpCommunications[i].mpc_name);
    //     }
    //     else {
    //       setChecked(false);
    //       setInputValue('');
    //     }
    //   };

    //   getMprDetail(item.mprd_id);

    // }, []);

    const handleClickOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(user_role === 'User' && !user_permissions.actions.find(action => action === 'makets-BIND_MP_COMMUNICATION_TO_THE_PHOTO')) {
          alert('В доступе отказано. Обратитесь к администратору');
          return;   
        }

        setOpen(true);
        setChecked(true);
    };

    const handleClose = () => {
        setOpen(false);
        // console.log('checked: ', checked);
    };

    const createCommunication = () => {      

      if(!inputValue) {
        alert('Пустая строка');
        return;
      }

      const index = mpCommunications.findIndex(item => item.mpc_name === inputValue);
        if(index === -1) {
          console.log('ТАКОЙ КОММ. ЕЩЕ НЕТ');

          const newCommunication = {
            mpc_mp_id: item.mp_id,
            mpc_name: inputValue,
            mpc_deleted: 0,
          }
          console.log('NEW COMMUNICATION: ', newCommunication);
          console.log('mprd_id: ', item.mprd_id);

          const mLog1 = {
            ml_mu_id: user_id,
            ml_mt_id: 3,
            ml_ma_id: 10, 
            ml_qty: 1,
            ml_comments: JSON.stringify(newCommunication),
          };

          const mLog2 = {
            ml_mu_id: user_id,
            ml_mt_id: 3,
            ml_ma_id: 8, 
            ml_qty: 1,
            ml_comments: JSON.stringify({
              operation: 'BIND',
              mprd_id: item.mprd_id,
              mpc_name: newCommunication.mpc_name,
            }),
          }

          dispatch(createMpCommunication(newCommunication))
            .then(data => {
              console.log('КОММУНИКАЦИЯ СОЗДАНА -> ПРОСТАВЛЯЕМ в mprDetail:', data);
              dispatch(updateMprDetail(item.mprd_id, {
                mprd_communication_id: data.mpc_id,
              }));
              dispatch(updateFilteredMpDetail(item.mprd_id, data.mpc_id, newCommunication.mpc_name));
              setItem({
                ...item,
                mprd_communication_id: data.mpc_id,
                mpc_name: newCommunication.mpc_name,
              })
            })
            .then(() => {
              setChecked(true);
              setOpen(false);
              console.log('ПРИВЯЗКА ЗАВЕРШЕНА');
              // dispatch(getFilteredMpDetails(filter));
            })
            .then(() => {
              dispatch(saveLog(mLog1))
                .then(data => console.log('СОЗДАТЬ НОВУЮ КОММУНИКАЦИЮ - ЛОГ СОХРАНЕН:', data))
                .catch(e => {
                  console.log(e);
                });
            })
            .then(() => {
              dispatch(saveLog(mLog2))
                .then(data => console.log('ПРОСТАВИТЬ КОММУНИКАЦИЮ - ЛОГ СОХРАНЕН:', data))
                .catch(e => {
                  console.log(e);
                });
            })    
            .catch(e => console.log(e));
        }
        else {

          const mLog3 = {
            ml_mu_id: user_id,
            ml_mt_id: 3,
            ml_ma_id: 8, 
            ml_qty: 1,
            ml_comments: JSON.stringify({
              operation: 'BIND',
              mprd_id: item.mprd_id,
              mpc_name: mpCommunications[index].mpc_name,
            }),
          };

          console.log('ТАКАЯ КОММ. СУЩЕСТВУЕТ, ЕЕ id = ', mpCommunications[index].mpc_id );
          dispatch(updateMprDetail(item.mprd_id, {                                          // update mpr_details table inside database
            mprd_communication_id: mpCommunications[index].mpc_id
          }));
          dispatch(updateFilteredMpDetail(item.mprd_id, mpCommunications[index].mpc_id, mpCommunications[index].mpc_name));  // update filteredMpDetails inside store
          setItem({
            ...item,
            mprd_communication_id: mpCommunications[index].mpc_id,
            mpc_name: mpCommunications[index].mpc_name,
          })

          setChecked(true);
          setOpen(false);
          console.log('ПРИВЯЗКА ЗАВЕРШЕНА');
          // dispatch(getFilteredMpDetails(filter));

          dispatch(saveLog(mLog3))
            .then(data => console.log('ПРОСТАВИТЬ КОММУНИКАЦИЮ - ЛОГ СОХРАНЕН:', data))
            .catch(e => {
              console.log(e);
            });
        }
    };

    const cancelCommunication = () => { 
      
      const mLog4 = {
        ml_mu_id: user_id,
        ml_mt_id: 3,
        ml_ma_id: 8, 
        ml_qty: 1,
        ml_comments: JSON.stringify({
          operation: 'UNBIND',
          mprd_id: item.mprd_id,
        }),
      }

      dispatch(updateMprDetail(item.mprd_id, {                   // update mpr_details table inside database
        mprd_communication_id: 0,
      }))
      .then(() => {
        dispatch(saveLog(mLog4))
          .then(data => console.log('ОТМЕНА КОММУНИКАЦИИ - ЛОГ СОХРАНЕН:', data))
          .catch(e => {
            console.log(e);
          });
      })    
      .catch(e => console.log(e));


      dispatch(updateFilteredMpDetail(item.mprd_id, 0, null));        // update filteredMpDetails inside store

      setItem({
        ...item,
        mprd_communication_id: 0,
        mpc_name: null,
      })
      setChecked(false);
      setOpen(false);

      console.log('ОТВЯЗКА ЗАВЕРШЕНА');
      // dispatch(getFilteredMpDetails(filter));
    };

    // console.log('item: ', item);
    
    // console.log('mpc_name: ', item.mpc_name);
    // console.log('checked: ', checked);
    // console.log('value: ', value);
    // console.log('inputValue: ', inputValue); 

  return (
    <div>
        <Checkbox
          checked={checked}
          color="secondary"
          className={classes.checkbox}
          onClick={handleClickOpen}            
        />
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
            <DialogTitle id="form-dialog-title">Макет & Коммуникация</DialogTitle>
            <DialogContent>
                {/* <Typography variant="h4" className={classes.communication}>{item.mpc_name}</Typography> */}
                <Autocomplete
                    freeSolo
                    filterOptions={filterOptions}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    id="free-solo-2-demo"
                    options={mpCommunications}
                    getOptionSelected={(option, value) => option.mpc_name === value.mpc_name }
                    getOptionLabel={(option) => option.mpc_name }
                    style={{ width: 550 }}
                    renderInput={(params) => <TextField {...params} label={checked ? "Редактировать Коммуникацию" : "Добавить Коммуникация"} variant="outlined" color="secondary"/>}
                  />
            </DialogContent>
            <DialogActions className={classes.dialogactions}>
            <Button onClick={cancelCommunication} color="primary">
                Удалить
            </Button>
            <Button onClick={createCommunication} color="secondary">
                Сохранить
            </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}