import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { format, parseJSON, getWeek } from 'date-fns';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import {pathToSmallPhoto} from '../../utils/shared';

export default function Reports2() {
    const {selectedIndex} = useSelector(state => state.shared);
    const {filteredMpDetails} = useSelector(state => state.mpDetails);

    const [base64, setBase64] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const excelExport = () => {

    const dataForReport = selectedIndex === 2
        ? filteredMpDetails.filter(item => item.mpc_name !== null)
        : filteredMpDetails;
    console.log('dataForReport:', dataForReport); 

    let wb = new ExcelJS.Workbook();
    let workbookName = "WO_Report#2.xlsx";
    let worksheetName = "Data";
    let worksheetName2 = "List";
    let ws = wb.addWorksheet(worksheetName);

    ws.columns = [
    {
      key: "city", 
      header: "Город", 
      width: 18 
    },
    {
      key: "address", 
      header: "Адрес", 
      width: 40 
    },
    {
      key: "side", 
      header: "Ст.", 
      width: 4 
    },
    {
      key: "side_type", 
      header: "Ст. AB", 
      width: 4 
    },
    {
      key: "size", 
      header: "Размер", 
      width: 8 
    },
    {
      key: "type", 
      header: "Тип констр.", 
      width: 18 
    },
    {
      key: "owner", 
      header: "Владелец", 
      width: 18 
    },
    {
      key: "plan_date", 
      header: "Дата план", 
      width: 14,
      style: {
      alignment: { horizontal: "center" },
      }
    },
    {
      key: "fact_date", 
      header: "Дата мониторинга", 
      width: 14,
      style: {
      alignment: { horizontal: "center" },
      }
    },
    {
      key: "fact_time", 
      header: "Время мониторинга", 
      width: 14,
      style: {
      alignment: { horizontal: "center" },
      }
    },
    {
      key: "link", 
      header: "Фото", 
      width: 14,
      style: {
      alignment: { horizontal: "center" },
      } 
    },
    {
        key: "client", 
        header: "Клиент", 
        width: 20 
    },
    {
      key: "client_type", 
      header: "Категория", 
      width: 35
    },
    {
      key: "qty", 
      header: "К-во выходов", 
      width: 10,
      style: {
        alignment: { horizontal: "center" },
        } 
    },
    {
        key: "communication", 
        header: "Коммуникация", 
        width: 35,
    },
    // {
    //     key: "year", 
    //     header: "Год", 
    //     width: 8,
    //     style: {
    //     alignment: { horizontal: "center" },
    //     } 
    // },
    // {
    //     key: "month", 
    //     header: "Месяц", 
    //     width: 8,
    //     style: {
    //     alignment: { horizontal: "center" },
    //     }  
    // },
    // {
    //     key: "week", 
    //     header: "Неделя", 
    //     width: 8,
    //     style: {
    //     alignment: { horizontal: "center" },
    //     } 
    // },
    // {
    //     key: "date", 
    //     header: "Дата мониторинга", 
    //     width: 14,
    //     style: {
    //     alignment: { horizontal: "center" },
    //     }
    // },
    // {
    //     key: "link", 
    //     header: "Фото", 
    //     width: 8,
    //     style: {
    //     alignment: { horizontal: "center" },
    //     } 
    // },
    ];

    ws.getRow(1).alignment = { 
    vertical: 'top',
    horizontal: 'center',
    wrapText: true
    };

    ws.getRow(1).font = {
      bold: true,
    };

    for (let i = 0; i<dataForReport.length; i++){
        
        ws.addRow({
            city: dataForReport[i].mpd_city,
            address: dataForReport[i].mpd_address,
            side: dataForReport[i].mpd_side,
            side_type: dataForReport[i].mpd_side.substring(0,1),
            size: dataForReport[i].mpd_size,
            type: dataForReport[i].mpd_unit_type,
            owner: dataForReport[i].mpd_owner,
            plan_date: format(parseJSON(dataForReport[i].mpr_planned_date), 'dd.MM.yyyy'),
            fact_date: format(parseJSON(dataForReport[i].mprdp_photo_assigned_date), 'dd.MM.yyyy'),
            fact_time: format(parseJSON(dataForReport[i].mprdp_photo_assigned_date), 'HH:mm:ss'),
            link: pathToSmallPhoto(dataForReport[i]),
            client: dataForReport[i].cg_name || dataForReport[i].mprd_brand,
            client_type: dataForReport[i].ctg_name,
            qty: 1,
            communication: dataForReport[i].mpc_name,
        });

        ws.getCell(`K${i+2}`).value = {
            text: "фото (1 шт.)",
            hyperlink: pathToSmallPhoto(dataForReport[i]),
        };
    
        ws.getCell(`K${i+2}`).font = {
            color: { argb: "0000FF" },
            underline: true
        };
    }
    


    wb.xlsx.writeBuffer()
        .then(function(buffer){
            saveAs(
                // new Blob([buffer], { type: "application/vnd.ms-excel" }),
                new Blob([buffer], { type: "application/octet-stream" }),  
                workbookName
            );
        });
  }
 
  return (
      <Button
        variant="contained"
        color="default"
        startIcon={<GetAppIcon />}
        onClick={excelExport}
      >
        Отчет 2
      </Button>
  )
}