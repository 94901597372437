import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PhotosDialog from '../../components/photosDialog/photosDialog'; 
import PhotosActions from '../../components/photosActions/photosActions';
import Image from '../../components/image/image';
import Spinner from '../../components/spinner/spinner';

import { resetTheStore } from '../../redux/actions/shared';
import { retrieveMonitoringProjects } from '../../redux/actions/monitoringProjects';
import { retrieveMpDetails, getAllCitiesFromMpDetails } from '../../redux/actions/mpDetails';
import { getBrandsFromMprDetails } from '../../redux/actions/mprDetails';
import { retrieveClientsGo, getClientsGoAndBrands } from '../../redux/actions/clientsGo'; 

import {to7digitals, getRandomIntInclusive, isInitialState} from '../../utils/shared';
import {_baseApiUrl} from '../../redux/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row', 
        fleWrap: 'nowrap',
        maxHeight: '900px;',
        maxWidth: '1200px;',
      },
    success: {
        color: '#fff',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    photoContainer: {
        marginTop: '8px;',
    },
    typography: {
        padding: theme.spacing(2),
    },
  }));  

const Photos = () => {
    const classes = useStyles();

    useEffect(() => {
        dispatch(resetTheStore());
    }, []);

    const {monitoringProjects} = useSelector(state => state.monitoringProjects);
    const {mpDetails, mpDetailsWithoutBrand} = useSelector(state => state.mpDetails);
    const {clientsGoAndBrands} = useSelector(state => state.clientsGo);
    // const {filter} = useSelector(state => state.shared);

    const dispatch = useDispatch();

    const [prevMpDetail, setPrevMpDetail] = useState(null); 
    const [isCanceled, setCanceled] = useState(false); 

    useEffect(() => {
        dispatch(retrieveMonitoringProjects());
        // dispatch(retrieveMpDetails()); 
        dispatch(retrieveClientsGo());
        dispatch(getBrandsFromMprDetails());
        dispatch(getAllCitiesFromMpDetails());
    }, []);
    
    let mpDetail;
    let path;

    // if(!isInitialState(filter) && mpDetailsWithoutBrand.length === 0) {
    //     alert("Для заданного фильтра нет Фото без Бренда")
    // }

    if(mpDetailsWithoutBrand.length>0) {

        let randId = getRandomIntInclusive(0, mpDetailsWithoutBrand.length - 1);

        mpDetail = isCanceled && prevMpDetail
            ? prevMpDetail 
            : mpDetailsWithoutBrand[randId];
        
        path = mpDetail
            ? "https://monitoring.graceoutdoor.ru/" + to7digitals(mpDetail.mpr_id) + "/" + to7digitals(mpDetail.mprdp_photo_assigned_uf_id) + "_" + to7digitals(mpDetail.uf_rand) + "." + mpDetail.uf_extension
            : "https://via.placeholder.com/720x480"
    }
    
    // console.log('mpDetails / Photos: ', mpDetails);
    // console.log('mpDetailsWithoutBrand / Photos: ', mpDetailsWithoutBrand.length);
    // console.log('randId: ', randId);
    // console.log('prevMpDetail / Photos: ', prevMpDetail);
    // console.log('isCaneled? / Photos: ', isCanceled); 
    // console.log('clientsGoAndBrands / Photos: ', clientsGoAndBrands);   

   
    return (
            <div className={classes.root}>
                <Grid container spacing={2} className={classes.root}> 

                    {monitoringProjects &&
                    <Grid item>
                        <PhotosDialog monitoringProjects={monitoringProjects}/>
                    </Grid>}

                    {clientsGoAndBrands.length> 0 && mpDetailsWithoutBrand.length >= 0 &&
                    <Grid item>
                        <PhotosActions
                            options={clientsGoAndBrands}
                            mpDetail={mpDetail}
                            setPrevMpDetail={setPrevMpDetail}
                            setCanceled={setCanceled} 
                            fullWidth />
                    </Grid>}
                
                    {mpDetailsWithoutBrand.length>0 &&
                    <Grid item>
                        <Image 
                            path={path}
                            height={'800px;'}                          
                        />
                    </Grid>}
            
                </Grid>
                               
            </div>
        )
};

export default Photos;
