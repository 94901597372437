import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';

import { getFilteredMpDetails } from '../../redux/actions/mpDetails';
import {retrieveMpCommunications} from '../../redux/actions/mpCommunications';  

import MaketsImage from '../maketsImage/maketsImage';
import MaketsCommList from '../maketsCommList/maketsCommList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    margin: '0px;',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
    marginLeft: '0px;',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      
  },
  icon: {
    visibility: 'visible',
    backgroundColor: 'white',
    // backgroundColor: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    color: 'action',
  },
  chosen: {
    visibility: 'visible',
    backgroundColor: 'white',    
  },
  checkbox: {
    visibility: 'visible',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    color: "secondary",
    borderRadius: '0',
    padding: '5px;',
  },
  tooltip: {
    fontSize: '1rem',
    maxWidth: '600px;',
    backgroundColor: 'white',
    color: 'black',
  },
  formcontrol: {
    paddingLeft: '14px;'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginBottom: '20px;',
  }


}));

export default function MaketsPhotos() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {filteredMpDetails} = useSelector(state => state.mpDetails);
  const {selectedIndex, duplicatesFlag, filter} = useSelector(state => state.shared);


  if(filteredMpDetails.length !== filteredMpDetails.map(item => item.mprd_id).length) alert("Есть дубли по mprd_id");

  let array;

  if(selectedIndex === 2) {
    array = filteredMpDetails.filter(item => item.mprd_communication_id !== 0);
  }
  else if (selectedIndex === 3) {
    array = filteredMpDetails.filter(item => item.mprd_communication_id === 0);
  }
  else {
    array = filteredMpDetails;
  }

  if(duplicatesFlag == 'Yes') {
    const duplicates = filteredMpDetails.filter(item => item.mprd_communication_id !== 0);
    array = duplicates.reduce(function(acc, item, index, duplicates){
      const newArray = [...duplicates.slice(0,index), ...duplicates.slice(index+1)].map(item => item.mpc_name); 
      if(newArray.includes(item.mpc_name)) {
        return acc.concat(item)
      }
      else {
        return acc
      }
    }, []);
  }  

  // console.log('filteredMpDetails: ', filteredMpDetails);
  console.log('array: ', array);


  return (
          <div className={classes.root}>
              <GridList className={classes.imageList} >              
                  {array.map((item) => (
                  
                  <MaketsImage 
                    key={item.mprdp_photo_assigned_uf_id}
                    mpDetail={item}             
                  />
                
                  ))}
                
              </GridList>

            {array.length>0 &&
            <Grid container spacing={2} >
              <MaketsCommList /> 
            </Grid>}
            
          </div>
       )
}