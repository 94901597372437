import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import MaketsImageDialog from '../maketsImageDialog/maketsImageDialog';

import {to7digitals, loadImage} from '../../utils/shared';
import './maketsImage.scss'; 
import Spinner from '../spinner/spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: theme.palette.primary.light,
    marginLeft: '0px;',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      
  },
  icon: {
    visibility: 'visible',
    backgroundColor: 'white',
    // backgroundColor: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    color: 'action',
  },
  iconbutton: {
    paddingBottom: '6px;',
  },
  chosen: {
    visibility: 'visible',
    backgroundColor: 'white',    
  },
  checkbox: {
    visibility: 'visible',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    color: "secondary",
    borderRadius: '0',
    padding: '5px;',
  },
  tooltip: {
    fontSize: '1rem',
    maxWidth: '600px;',
    backgroundColor: 'white',
    color: 'black',
  },
  tooltiptext: {
    color: '#00F',
  },
  imagelistitem: {
      marginRight: '10px;',
  },
  showImage: {
    height: '300px;',
    width: 'auto',
    opacity: '1',
  },
  hideImage: {
    height: '0px;',
    overflow: 'hidden',
    opacity: '0',
  
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  dialog: {
    overflowY: 'unset',
  },
  customizedButton: {
    position: 'absolute',
    left: '92%',
    top: '0%',
    // backgroundColor: 'lightgray',
    color: 'black',
  }

}));


export default function MaketsImage(props) {
  const classes = useStyles();

  const {mpDetail} = props;  

  const [item, setItem] = useState(mpDetail);

  const [isImageLoaded, setImageLoaded] = useState(false);


  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);   // open fullWidth image

  let path = "https://monitoring.graceoutdoor.ru/" + to7digitals(item.mpr_id) + "/" + to7digitals(item.mprdp_photo_assigned_uf_id) + "_" + to7digitals(item.uf_rand) + "_900." + item.uf_extension;

  useEffect(() => {
    let image = document.querySelector(`img[src="${path}"]`);
    loadImage(path, image)
      .then(() => setImageLoaded(true))
      .catch(() => {
        path = "https://via.placeholder.com/100x100";
        setImageLoaded(true);
      })
  }, []);

  const handleDoubleClick = (e) => {
    setOpen((prev) => !prev);
  };

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  return isImageLoaded 
    ? (
          <GridListTile 
            className={classes.imagelistitem}
            aria-owns={openPopover ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onDoubleClick={handleDoubleClick}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >

              <Dialog
                classes={{paper: classes.dialog}}
                aria-labelledby="simple-dialog-title" 
                open={open}>

                <img
                  width="800"
                  src={"https://monitoring.graceoutdoor.ru/" + to7digitals(item.mpr_id) + "/" + to7digitals(item.mprdp_photo_assigned_uf_id) + "_" + to7digitals(item.uf_rand) + "." + item.uf_extension} 
                  alt={item.mpc_name ? item.mpc_name : 'No communication'} 
                />
              </Dialog>
            
              
              <img
                className={classes.showImage}
                src={path} 
                alt={`${path}, mprd_id: ${item.mprd_id}`}
              />
            
              <GridListTileBar
                // style={{marginLeft: '0px !important'}}
                // // title={<MaketsImageDialog item={item} />} 
                // classes={{
                //   root: classes.titleBar,
                //   title: classes.title,
                // }}
                actionIcon={
                  <IconButton
                    className={classes.iconbutton}
                    // aria-label={`star ${item.mpc_name ? item.mpc_name : 'No communication'}`}
                    // onClickCapture={handleAction}
                  >

                    <MaketsImageDialog 
                      item={item}
                      setItem={setItem} 
                    />

                  </IconButton>
                }
                actionPosition={"left"}
              />              
              

              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography variant="h5">
                  {item.cg_name}
                </Typography>
                <Typography variant="subtitle1" className={classes.tooltiptext}>
                  {item.mpc_name ? item.mpc_name : '' }
                </Typography>
            </Popover>
                    
              
          </GridListTile>
  )
  : (
    <Spinner />
  )
}
